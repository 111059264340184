import { IRole, IRoleData, IRoleFilter } from './../../types/role';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { PERMISSION_LEVEL } from 'src/constants/permissions';
import { IUser } from 'src/types/auth';
import { IPagination } from 'src/types/common';
import { IPermissionScheme } from 'src/types/permission';
import { getPermissionsName } from 'src/utils/role';
import { toastMessage } from 'src/utils/toast';
import { getPermissions } from '../permission/permission_action';
import client from 'src/clients/http';

export const createRole = createAsyncThunk<IRole, IRole>(
  'roles/createRole',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.createRole(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const searchRoles = createAsyncThunk<IPagination<IRoleData>, IRoleFilter>(
  'roles/searchRoles',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const data = await client.searchRoles(payload);
      if (data.data.length) {
        for (const role of data.data) {
          const result = (await dispatch(getUsersInRole(role.id as string))).payload as IUser[];
          role.users = result;
        }
        const permissions = await dispatch(getPermissions(PERMISSION_LEVEL.SYSTEM));
        if (permissions.payload) {
          const payload: any = permissions.payload;
          if (payload.length) {
            for (const role of data.data) {
              const { permissionsName } = getPermissionsName(
                permissions.payload as IPermissionScheme[],
                role.permissions,
                '',
              );
              role.permissionsName = permissionsName;
            }
          }
        }
      }
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getAllRoles = createAsyncThunk<IRole[], void>(
  'roles/getAllRoles',
  async (_, { rejectWithValue }) => {
    try {
      const data = await client.getAllRoles();
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getUsersInRole = createAsyncThunk<IUser[], string>(
  'roles/getUsersInRole',
  async (roleId, { rejectWithValue }) => {
    try {
      const data = await client.getUsersInRole(roleId);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getRole = createAsyncThunk<IRole, string>(
  'roles/getRole',
  async (roleId, { rejectWithValue }) => {
    try {
      const data = await client.getRole(roleId);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const updateRole = createAsyncThunk<IRole, { roleId: string; data: IRole }>(
  'roles/updateRole',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.updateRole(payload.roleId, payload.data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteRole = createAsyncThunk<string, string>(
  'roles/deleteRole',
  async (payload, { rejectWithValue }) => {
    try {
      await client.deleteRole(payload);
      return payload;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);
