/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/media-has-caption */
import { Box, Divider } from '@mui/material';
import { Fragment, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import _, { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import BoxRightContent from 'src/components/box_right_content';
import Loading from 'src/components/loading';
import ViewDetailNotificationDrawer from 'src/components/notification/view_detail_drawer';
import Sidebar from 'src/components/sidebar';
import SidebarSmall from 'src/components/sidebar_small';
import Topbar from 'src/components/topbar';
import { ROLE_LEVEL } from 'src/constants/role';
import NotPermission from 'src/pages/not_permission';
import store, { useAppDispatch, useAppSelector } from 'src/redux_store';
import { openModal, resetModal } from 'src/redux_store/common/modal/modal_slice';
import { closeBoxRightContent } from 'src/redux_store/common/topbar/topbar_slice';
// import {
//   getNotificationSetting,
//   markAllAsSeenNotification,
//   unseenNotification,
// } from 'src/redux_store/notification/notification_action';
import { ws } from 'src/clients/websocket_new';
import ModalAutoLogout from 'src/components/modal/auto_lougout';
import ModalReloadPage from 'src/components/modal/reload_page';
import { MODAL_IDS } from 'src/constants/modal';
import { PATH } from 'src/routes/path';
import { ROUTE_LIST } from 'src/routes/route_list';
import { isVMSCloud } from 'src/utils/common';
import { useStyles } from './style';

const AdminLayout = (props: PropsWithChildren) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const location = useLocation();
  const audioRef = useRef<HTMLAudioElement>(new Audio());

  const {
    me,
    token,
    role,
    statusGlobal,
    isReloadPageEventSocket,
    autoLogout: { isAutoLogout },
  } = useAppSelector((state) => state.myAccountSlice);
  const { openRightContent } = useAppSelector((state) => state.topBarSlice);

  // const isLoadingGetMe = useIsRequestPending('myAccount', 'getMe');
  // const isLoadingCustomers = useIsRequestPending('myAccount', 'getMyCustomers');

  const { numberOfNotifications, infoNotificationToast, notificationSetting } = useAppSelector(
    (state) => state.notificationSlice,
  );

  const [count, setCount] = useState(0);
  const [tabHasFocus, setTabHasFocus] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const toggleSidebar = () => setOpen((prev) => !prev);

  useEffect(() => {
    if (token) {
      ws.initialize(token);
    }
  }, [token]);

  useEffect(() => {
    if (isReloadPageEventSocket) {
      dispatch(openModal({ modalId: MODAL_IDS.reloadPage, dialogComponent: <ModalReloadPage /> }));
    }
  }, [isReloadPageEventSocket]);

  useEffect(() => {
    if (isAutoLogout) {
      dispatch(openModal({ modalId: MODAL_IDS.autoLogout, dialogComponent: <ModalAutoLogout /> }));
    }
  }, [isAutoLogout]);

  // useEffect(() => {
  //   if (!me.id) return;
  //   dispatch(unseenNotification());
  //   dispatch(getNotificationSetting());
  // }, []);

  useEffect(() => {
    return () => {
      if (ws) {
        ws.close();
      }
      dispatch(closeBoxRightContent());
    };
  }, []);

  useEffect(() => {
    return () => {
      const { modalSlice } = store.getState();

      if (!_.isEmpty(modalSlice)) {
        dispatch(resetModal());
      }
    };
  }, [location]);

  const checkPlaySound = (): boolean => {
    if (_.isEmpty(infoNotificationToast) || _.isEmpty(notificationSetting)) return false;

    const { placeOfOrigin } = infoNotificationToast;
    const { notificationSources } = notificationSetting;
    const notificationItemSource = notificationSources.find(
      (item) => item.source === placeOfOrigin,
    );

    if (!_.isEmpty(notificationItemSource)) {
      const sound = notificationItemSource.sound;

      return sound;
    }

    return true;
  };

  // const handleShowPopup = () => {
  //   const { popupStatus } = notificationSetting;

  //   if (!popupStatus) return;

  //   toast(<NotificationItem notification={infoNotificationToast} isToastNotification />);
  // };

  // const handleClickSound = async () => {
  //   if (audioRef.current) {
  //     const levelNotification = infoNotificationToast.level.toLowerCase();
  //     const notificationLevels = notificationSetting.notificationLevels;
  //     const notificationLevelItem =
  //       notificationLevels[levelNotification as keyof INotificationLevel];

  //     if (
  //       notificationLevelItem.status &&
  //       notificationLevelItem.soundRingtone !== 'OFF' &&
  //       checkPlaySound()
  //     ) {
  //       const sound =
  //         soundList.find((item) => item.id === notificationLevelItem.soundRingtone) || soundList[0];
  //       audioRef.current.src = sound.source;
  //       await audioRef.current?.play();
  //     }
  //   }
  // };

  const renderTitleSystem = () => {
    document.title = isVMSCloud ? 'DANAPOD' : 'Camera Hội An';
  };

  const handleResetTitle = () => {
    renderTitleSystem();
    setCount(0);
  };

  const handleFocus = () => {
    setTabHasFocus(true);
  };

  const handleBlur = () => {
    setTabHasFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  useEffect(() => {
    renderTitleSystem();
  }, []);

  useEffect(() => {
    let timer: any;
    if (tabHasFocus) {
      handleResetTitle();
    } else {
      if (numberOfNotifications > 0) {
        timer = setInterval(() => {
          if (count % 2 === 0) {
            document.title = `Bạn có ${numberOfNotifications} thông báo mới`;
          } else {
            renderTitleSystem();
          }

          setCount(count + 1);
        }, 1500);
      } else {
        handleResetTitle();
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [numberOfNotifications, count, tabHasFocus]);

  // useEffect(() => {
  //   if (!_.isEmpty(infoNotificationToast)) {
  //     handleClickSound();
  //     handleShowPopup();
  //     if (
  //       (openRightContent && typeContentShow === 'notification') ||
  //       location.pathname === PATH.notification
  //     ) {
  //       dispatch(markAllAsSeenNotification());
  //     }
  //   }
  // }, [infoNotificationToast]);

  const getTitle = () => {
    if (!location.pathname) return '';
    const path = location.pathname;
    const route = ROUTE_LIST.find((route) => route.path === path);
    const titleKey = location.pathname.includes(PATH.customerManagement)
      ? 'routes.customerManagement'
      : route?.name;

    const permission = route?.permission;
    const isShow = _.isEmpty(_.intersection(permission, role.permissions));

    if (
      (role.level === ROLE_LEVEL.SUPER_ADMIN || !isShow || _.isEmpty(route?.permission)) &&
      titleKey
    ) {
      return t(titleKey);
    }

    return '';
  };

  const renderBody = () => {
    if (statusGlobal === 'pending') {
      return <Loading />;
    }

    if (isEmpty(role)) {
      return <NotPermission />;
    }

    return (
      <Fragment>
        {open ? (
          <Sidebar toggleSidebar={toggleSidebar} />
        ) : (
          <SidebarSmall toggleSidebar={toggleSidebar} />
        )}
        <Divider orientation="vertical" />
        <Box className={classes.wrapper}>
          <Box className={classes.page}>{props.children}</Box>
          {openRightContent && <BoxRightContent />}
        </Box>
      </Fragment>
    );
  };

  // loading get me action
  // if (isLoadingGetMe || isLoadingCustomers) {
  //   return (
  //     <Box className={classes.center}>
  //       <CircularProgress color="error" />
  //     </Box>
  //   );
  // }

  // if token not found redirect to login page

  if (isEmpty(me)) {
    return <Navigate to={PATH.login} state={{ from: location }} replace />;
  }

  return (
    <Box className={classes.root}>
      <Topbar title={getTitle()} />
      <Divider />
      <Box className={classes.container}>{renderBody()}</Box>

      <audio ref={audioRef} style={{ display: 'none' }}>
        <source src="" type="audio/mp3" />
      </audio>

      <ViewDetailNotificationDrawer />
    </Box>
  );
};

export default AdminLayout;
