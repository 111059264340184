import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { isArray, isEmpty } from 'lodash';

import moment from 'moment';
import client from 'src/clients/http';
import config from 'src/config';
import i18n from 'src/i18n';
import { IUser } from 'src/types/auth';
import { ICustomer } from 'src/types/customer';
import { TRequestStatusOption } from 'src/types/request_status';
import { IRole } from 'src/types/role';
import { IOrderInfo, TAutoLogout } from 'src/types/user';
import { createCustomer, updateCustomer } from '../customer/customers/actions';
import {
  getMe,
  getMyCustomers,
  getMyOrderRoles,
  getMyOrders,
  updateUserLocale,
} from './my_account_action';

interface IMyAccountState {
  token: string | null;
  me: IUser;
  codeMFA: { secret: string; qrCode: string };
  role: IRole;
  statusGlobal: TRequestStatusOption;
  customers: ICustomer[];
  customer: ICustomer | null;
  customerId: string;
  orders: IOrderInfo[];
  orderId: string;
  isReload: boolean;
  userSession: string | undefined;
  isReloadPageEventSocket: boolean;
  autoLogout: {
    isAutoLogout: boolean;
    type: TAutoLogout;
  };
}

const initialState: IMyAccountState = {
  token: '',
  me: {} as IUser,
  codeMFA: { secret: '', qrCode: '' },
  role: {} as IRole,
  statusGlobal: 'not_started',
  customers: [],
  customer: null,
  customerId: '',
  // customerId: '649e4540e7cc463f1303f6c2',
  orders: [],
  orderId: '',
  isReload: true,
  userSession: Cookies.get('USER_SESSION'),

  isReloadPageEventSocket: false,
  autoLogout: {
    isAutoLogout: false,
    type: '',
  },
};

const myAccountSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    logoutLocal: (state) => {
      state.me = initialState.me;
      state.token = initialState.token;
      state.role = initialState.role;
      state.orders = initialState.orders;
      state.customers = initialState.customers;
      state.orderId = initialState.orderId;
      state.customerId = initialState.customerId;
      state.userSession = undefined;
      state.autoLogout = initialState.autoLogout;
      client.setToken('');
      client.setOrderId('');
      Cookies.remove('USER_SESSION', { path: '/', domain: config.domain });
    },

    deleteCookieUserSession: () => {
      Cookies.remove('USER_SESSION', { path: '/', domain: config.domain });
    },

    setLoadingGlobal: (state, action: PayloadAction<TRequestStatusOption>) => {
      state.statusGlobal = action.payload;
    },

    changeIsReload: (state, action) => {
      state.isReload = action.payload;
    },

    changeUserSession: (state, action) => {
      state.userSession = action.payload;
    },

    changeIsReloadPageEventSocket: (state, action: PayloadAction<boolean>) => {
      state.isReloadPageEventSocket = action.payload;
    },

    changeIsAutoLogoutEventSocket: (
      state,
      action: PayloadAction<{ isAutoLogout: boolean; type: TAutoLogout }>,
    ) => {
      state.autoLogout = action.payload;
    },

    updateCustomers: (state, action: PayloadAction<ICustomer[]>) => {
      state.customers = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getMe.fulfilled, (state, action) => {
      const locale = action.payload.locale;
      Cookies.set('locale', locale, { path: '/', domain: config.domain });
      localStorage.setItem('i18nextLng', locale);
      i18n.changeLanguage(locale);
      // moment.locale(locale);
      moment.defineLocale(locale, {});
      state.token = Cookies.get('USER_SESSION') || '';
      state.userSession = Cookies.get('USER_SESSION');
      state.me = action.payload;
    });
    builder.addCase(getMe.rejected, (state) => {
      state.token = '';
      state.me = {} as IUser;
      client.setToken('');
      Cookies.remove('USER_SESSION', { path: '/', domain: config.domain });
      state.role = initialState.role;
      state.orders = initialState.orders;
      state.customers = initialState.customers;
      state.orderId = initialState.orderId;
      state.customerId = initialState.customerId;
      client.setOrderId('');
    });
    // builder.addCase(logout.fulfilled, (state) => {
    //   state.me = initialState.me;
    //   state.token = initialState.token;
    //   state.role = initialState.role;
    //   state.orders = initialState.orders;
    //   state.customers = initialState.customers;
    //   state.orderId = initialState.orderId;
    //   state.customerId = initialState.customerId;
    //   client.setToken('');
    //   client.setOrderId('');
    //   Cookies.remove('USER_SESSION', { path: '/', domain: config.domain });
    // });
    // builder.addCase(logout.rejected, (state) => {
    //   client.setToken('');
    //   Cookies.remove('USER_SESSION', { path: '/', domain: config.domain });

    //   state.role = initialState.role;
    //   state.orders = initialState.orders;
    //   state.customers = initialState.customers;
    //   state.orderId = initialState.orderId;
    //   state.customerId = initialState.customerId;
    //   client.setOrderId('');
    // });
    builder.addCase(getMyCustomers.pending, (state) => {
      state.statusGlobal = 'pending';
      state.customers = [];
      state.customerId = '';
      state.customer = null;
    });

    builder.addCase(getMyCustomers.fulfilled, (state, action) => {
      if (!isArray(action.payload) || action.payload.length === 0) {
        state.role = initialState.role;
        state.statusGlobal = 'rejected';
      } else {
        state.customers = action.payload;
        state.customer = action.payload[0];
        state.statusGlobal = 'fulfilled';
      }
    });

    builder.addCase(getMyCustomers.rejected, (state) => {
      state.customers = [];
      state.customerId = '';
      state.statusGlobal = 'rejected';
      state.role = initialState.role;
    });

    builder.addCase(getMyOrders.pending, (state, action) => {
      state.customerId = action.meta.arg;
      state.customer = state.customers.find((item) => item.id === action.meta.arg) as ICustomer;
      state.orders = [];
      state.orderId = '';
      state.statusGlobal = 'pending';
    });

    builder.addCase(getMyOrders.fulfilled, (state, action) => {
      if (!isArray(action.payload) || action.payload.length === 0) {
        state.role = initialState.role;
        state.statusGlobal = 'rejected';
      } else {
        state.customerId = action.meta.arg;
        state.customer = state.customers.find((item) => item.id === action.meta.arg) as ICustomer;
        state.orders = action.payload;
        state.statusGlobal = 'fulfilled';
      }
    });

    builder.addCase(getMyOrders.rejected, (state) => {
      state.orders = [];
      state.orderId = initialState.orderId;
      state.statusGlobal = 'rejected';
      state.role = initialState.role;
    });

    builder.addCase(getMyOrderRoles.pending, (state, action) => {
      state.orderId = action.meta.arg;
      client.setOrderId(action.meta.arg);
      state.statusGlobal = 'pending';
    });

    builder.addCase(getMyOrderRoles.fulfilled, (state, action) => {
      if (!action.payload || isEmpty(action.payload)) {
        state.statusGlobal = 'rejected';
        state.role = initialState.role;
      } else {
        state.role = action.payload;
        state.orderId = action.meta.arg;
        client.setOrderId(action.meta.arg);
        state.statusGlobal = 'fulfilled';
      }
    });

    builder.addCase(getMyOrderRoles.rejected, (state) => {
      state.role = initialState.role;
      state.statusGlobal = 'rejected';
    });

    builder.addCase(createCustomer.fulfilled, (state, action) => {
      state.customers.push(action.payload);
      state.customerId = action.payload.id || '';
    });

    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      const index = state.customers.findIndex((customer) => customer.id === action.payload.id);
      if (index !== -1) {
        const customers = [...state.customers];
        const customer = { ...state.customers[index], ...action.payload.data };
        customers.splice(index, 1, customer);
        state.customers = customers;
        if (state.customer?.id === action.payload.id) {
          state.customer = customer;
        }
      }
    });

    builder.addCase(updateUserLocale.fulfilled, (state, action) => {
      const { data } = action.meta.arg;

      const currentMe = { ...state.me };
      state.me = Object.assign(currentMe, data);
    });
  },
});

const { actions, reducer } = myAccountSlice;
export const {
  logoutLocal,
  setLoadingGlobal,
  changeIsReload,
  changeUserSession,
  changeIsReloadPageEventSocket,
  changeIsAutoLogoutEventSocket,
  updateCustomers,
  deleteCookieUserSession,
} = actions;
export default reducer;
