import { Box } from '@mui/material';
import React from 'react';
import { Navigate, Route, Routes as Router } from 'react-router-dom';

import _ from 'lodash';
import Loading from 'src/components/loading';
import NotFound from 'src/components/not_found';
import { ROLE_LEVEL } from 'src/constants/role';
import { useAppSelector } from 'src/redux_store';
import { IRoute } from 'src/types/route';
import { ROUTE_LIST } from './route_list';
import { PATH } from './path';
import { getParamToNavigate } from 'src/utils/helper';
import { useIsRequestPending } from 'src/hooks';

const Routes = () => {
  const { role, me, customerId, customers } = useAppSelector((state) => state.myAccountSlice);

  const isLoadingGetMe = useIsRequestPending('myAccount', 'getMe');
  const isLoadingCustomers = useIsRequestPending('myAccount', 'getMyCustomers');

  const renderRouter = (route: IRoute) => {
    const Page = route.component;

    const permissions = role?.permissions || [];
    const isShow = !_.isEmpty(_.intersection(route.permission, permissions));

    if (
      route.isHaveRoute &&
      (role?.level === ROLE_LEVEL.SUPER_ADMIN || !route.permission || (isShow && route.permission))
    ) {
      return <Page />;
    }

    return <NotFound />;
  };

  const renderRedirect = () => {
    if (_.isEmpty(me))
      return (
        <>
          <Route path="/customers" element={<Navigate to={PATH.login} replace />} />
          <Route path="/" element={<Navigate to={PATH.login} replace />} />
        </>
      );

    if (customers.length)
      return <Route path={PATH.addCustomer} element={<Navigate to={'/'} replace />} />;

    return (
      <Route
        path="/"
        element={<Navigate to={getParamToNavigate(me, role, customerId)} replace />}
      />
    );
  };

  return (
    <>
      {isLoadingCustomers || isLoadingGetMe ? (
        <Loading />
      ) : (
        <Router>
          {renderRedirect()}

          {ROUTE_LIST.map((route) => {
            const Layout = route.layout ? route.layout : React.Fragment;

            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <Layout>
                    <React.Suspense
                      fallback={
                        <Box flex={1}>
                          <Loading />
                        </Box>
                      }
                    >
                      {renderRouter(route)}
                    </React.Suspense>
                  </Layout>
                }
              />
            );
          })}
        </Router>
      )}
    </>
  );
};

export default Routes;
