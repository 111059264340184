import React from 'react';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IErrorMessageProps {
  sx?: SxProps;
  marginTop?: number;
  textAlign?: 'center' | 'left' | 'right';
  onClick?: () => void;
  title?: string;
}

function ErrorMessage(props: IErrorMessageProps) {
  const { t } = useTranslation();
  const { marginTop = 2, textAlign = 'center', sx, title = t('message.error.main') } = props;

  return (
    <Box width="100%" sx={sx} textAlign={textAlign} marginTop={marginTop}>
      <Typography variant="body1" mb={1.5}>
        {title}
      </Typography>
      <Button onClick={props.onClick} variant="contained" color="primary">
        {t('button.retry')}
      </Button>
    </Box>
  );
}

export default ErrorMessage;
