import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import { ILogFilter } from 'src/types/log';
import {
  IPaginationStationLogs,
  IStation,
  IStationCard,
  IStationFilter,
  IStationFunction,
  IStationGeneral,
  IStationLog,
  IStationWorkingMode,
} from 'src/types/station';
import { toastMessage } from 'src/utils/toast';

export const getMyStation = createAsyncThunk<IStationCard[], IStationFilter>(
  'station/getMyStation',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.getMyStation(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error.message || 'Lỗi hệ thống');

      return rejectWithValue(error);
    }
  },
);

export const getUserStationConfig = createAsyncThunk<
  { trackAll: boolean; stationList: IStationCard[] },
  string
>('station/getUserStationConfig', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.getUserStationConfig(payload);
    return data;
  } catch (error: any) {
    toastMessage.error(error.message || 'Lỗi hệ thống');

    return rejectWithValue(error);
  }
});

export const createStation = createAsyncThunk<IStation, Omit<IStationGeneral, 'id'>>(
  'station/createStation',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.createStation(payload);

      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createStationLight = createAsyncThunk<
  { stationId: string; lightId: string; lightName: string },
  { stationId: string; name: string }
>('station/createStationLight', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.createStationLight(payload.stationId, payload.name);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const activeStation = createAsyncThunk<IStation, string>(
  'station/activeStation',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.activeStation(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateStationGeneral = createAsyncThunk<
  IStation,
  { stationId: string; data: Omit<IStationGeneral, 'id'> }
>('station/updateStationGeneral', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.updateStationGeneral(payload.stationId, payload.data);

    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateStationFunction = createAsyncThunk<
  IStation,
  {
    stationId: string;
    data: {
      functionEnabled: boolean;
    };
  }
>('station/updateStationFunction', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.updateStationFunction(payload.stationId, payload.data);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateWorkingMode = createAsyncThunk<
  { stationId: string; data: IStationWorkingMode },
  { stationId: string; data: IStationWorkingMode }
>('station/updateWorkingMode', async (payload, { rejectWithValue }) => {
  try {
    await client.updateWorkingMode(payload.stationId, payload.data);

    return payload;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateStationLight = createAsyncThunk<
  { stationId: string; lightId: string; lightName: string },
  { stationId: string; lightId: string; name: string }
>('station/updateStationLight', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.updateStationLight(payload.stationId, payload.lightId, payload.name);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getStations = createAsyncThunk<IStation[], IStationFilter>(
  'station/getStations',
  async (filter, { rejectWithValue }) => {
    try {
      const data = await client.getStations(filter);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationGeneral = createAsyncThunk<IStationGeneral, string>(
  'station/getStationGeneral',
  async (stationId, { rejectWithValue }) => {
    try {
      const data = await client.getStationGeneral(stationId);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationFunction = createAsyncThunk<IStationFunction, string>(
  'station/getStationFunction',
  async (stationId, { rejectWithValue }) => {
    try {
      const data = await client.getStationFunction(stationId);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationManage = createAsyncThunk<IStation[], IStationFilter | undefined>(
  'station/getStationManage',
  async (params, { rejectWithValue }) => {
    try {
      const data = await client.getStationManage(params);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationBaseInfo = createAsyncThunk<IStation, string>(
  'station/getStationBaseInfo',
  async (stationId, { rejectWithValue }) => {
    try {
      const data = await client.getStationBaseInfo(stationId);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteStation = createAsyncThunk<string, string>(
  'station/deleteStation',
  async (stationId, { rejectWithValue }) => {
    try {
      await client.deleteStation(stationId);
      return stationId;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteStationLight = createAsyncThunk<
  { stationId: string; lightId: string },
  { stationId: string; lightId: string }
>('station/deleteStationLight', async (payload, { rejectWithValue }) => {
  try {
    await client.deleteStationLight(payload.stationId, payload.lightId);
    return payload;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getStationLogs = createAsyncThunk<
  IPaginationStationLogs<IStationLog>,
  { stationId: string; params: ILogFilter }
>('station/getStationLogs', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.getStationLogs(payload.stationId, payload.params);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
