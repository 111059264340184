import { Apps, AppsOutlined, Map, MapOutlined } from '@mui/icons-material';

import { EPermissions } from 'src/types/enum';
import { IMenuRoute } from 'src/types/route';
import { PATH } from './path';

export const MENU_LIST: IMenuRoute[] = [
  {
    id: 'routes.stationMap',
    path: PATH.stationMap,
    icon: MapOutlined,
    activeIcon: Map,
    isHaveMenu: true,
    permission: [EPermissions.LIST_ORDER_RESOURCE],
    key: 'stations',
  },

  {
    id: 'routes.watchList',
    icon: AppsOutlined,
    activeIcon: Apps,
    path: PATH.watchList,
    isHaveMenu: true,
    permission: [EPermissions.LIST_ORDER_RESOURCE],
    key: 'watch_list',
  },
  // {
  //   id: 'routes.customerManagement',
  //   path: PATH.customerManagement,
  //   icon: SettingsOutlined,
  //   activeIcon: Settings,
  //   isHaveMenu: true,
  //   permission: [EPermissions.UPDATE_CUSTOMER],
  // },
  // {
  //   id: 'routes.usersManagement',
  //   path: PATH.userManagement,
  //   icon: GroupsOutlined,
  //   activeIcon: Groups,
  //   isHaveMenu: true,
  //   permission: [EPermissions.LIST_ORDER_MEMBER],
  // },
  // {
  //   id: 'menu.roleManagement',
  //   path: PATH.roleManagement,
  //   icon: GroupsOutlined,
  //   activeIcon: Groups,
  //   isHaveMenu: false,
  //   permission: [EPermissions.LIST_ORDER_MEMBER],
  // },
  // {
  //   id: 'routes.stationManagement',
  //   path: PATH.stationManagement,
  //   icon: AccountTreeOutlined,
  //   activeIcon: AccountTree,
  //   isHaveMenu: false,
  //   permission: [EPermissions.GET_LIST_STATION],
  // },
  // {
  //   id: 'routes.settingVideo',
  //   path: PATH.cameraManagement,
  //   icon: VideoSettingsOutlined,
  //   activeIcon: VideoSettings,
  //   isHaveMenu: false,
  //   permission: [EPermissions.GET_LIST_CAMERA],
  // },
];
