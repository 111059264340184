import React, { useEffect, useState } from 'react';
import { Box, SvgIconTypeMap, Typography } from '@mui/material';
import { PersonOutlined, GroupsOutlined } from '@mui/icons-material';
import { UseFormSetError } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { ICustomer, TEntityType } from 'src/types/customer';
import { useIsRequestPending } from 'src/hooks';
import { toastMessage } from 'src/utils/toast';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { getProducts } from 'src/redux_store/customer/products/actions';
import { createCustomer } from 'src/redux_store/customer/customers/actions';
import { ReactComponent as EmptyCustomerSvg } from 'src/assets/svg/customer/empty.svg';
import { getPackagesByProductId } from 'src/redux_store/customer/product_packages/actions';
import { getMyOrderRoles, getMyOrders } from 'src/redux_store/my_account/my_account_action';
import { ICustomerAddRequest } from '../customer_management/customer_add/general_info';
import AddCustomerForm from './add_customer_form';
import theme from 'src/theme';

const optionList = (
  t: TFunction<'translation', undefined>,
): {
  icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>> & { muiName: string };
  label: string;
  id: TEntityType;
}[] => [
  {
    icon: PersonOutlined,
    label: t('label.personal'),
    id: 'individual',
  },
  {
    icon: GroupsOutlined,
    label: t('label.organization.main'),
    id: 'enterprise',
  },
];

function EmptyCustomer() {
  const [isContinue, setIsContinue] = useState(false);
  const [useFor, setUseFor] = useState<TEntityType | ''>('');
  const isCreating = useIsRequestPending('customers', 'createCustomer');

  const { productPackages } = useAppSelector(({ productPackageSlice }) => productPackageSlice);
  const {
    me: { email, phone, name, description },
  } = useAppSelector(({ myAccountSlice }) => myAccountSlice);

  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getProducts())
      .unwrap()
      .then((products) => {
        dispatch(getPackagesByProductId(products[0]?.id));
      })
      .catch((error) => toastMessage.error(error?.message));
  }, [dispatch]);

  const handleClick = (type: TEntityType) => {
    setUseFor(type);
  };

  const addCustomer = (customer: ICustomer, setError?: UseFormSetError<ICustomerAddRequest>) => {
    const order = {
      packageId: productPackages[0].id,
      expiresAt: moment().add(3, 'months').format(),
    };

    dispatch(createCustomer({ order, customer }))
      .unwrap()
      .then((newCustomer) => {
        const from = `/customers/${newCustomer.id}/stations`;
        // toastMessage.success(t('message.customer.addSuccess'));
        navigation(from, { replace: true });
        if (!newCustomer.id) return;

        localStorage.setItem('customer_order', newCustomer.id);

        dispatch(getMyOrders(newCustomer.id))
          .unwrap()
          .then((data) => {
            if (data.length) {
              localStorage.setItem('order', data[0].order.id);
              dispatch(getMyOrderRoles(data[0].order.id));
            }
          });
      })
      .catch((error) => {
        setError ? toastMessage.setErrors(error, setError) : toastMessage.error(error?.message);
      });
  };

  const handleContinue = (status: boolean) => {
    if (useFor === 'enterprise') return setIsContinue(status);

    const customer: ICustomer = {
      email,
      name,
      phone,
      address: '',
      description,
      entityType: 'individual',
    };

    addCustomer(customer);
  };

  return (
    <Box display="flex" flex={1} justifyContent="center">
      <Box flex={0.4} display="flex" flexDirection="column" gap={4} justifyContent="center">
        <Box width="70%">
          {isContinue ? (
            <AddCustomerForm handleContinue={handleContinue} addCustomer={addCustomer} />
          ) : (
            <Box flex={0.4} display="flex" flexDirection="column" gap={4} justifyContent="center">
              <Box>
                <Typography component="h2" variant="h5">
                  {t('label.question.danapod.main')}
                </Typography>
                <Typography>{t('label.question.danapod.description')}</Typography>
              </Box>
              <Box gap={2} display="flex">
                {optionList(t).map(({ icon: Icon, id, label }) => (
                  <Box
                    boxShadow={
                      id !== useFor
                        ? '0px 0px 0px 1px #B2BEC3'
                        : `0px 0px 0px 1px ${theme.palette.primary.main}`
                    }
                    display="inline-block"
                    px={4}
                    py={3}
                    borderRadius={4}
                    textAlign="center"
                    key={id}
                    onClick={() => handleClick(id)}
                    sx={{
                      cursor: 'pointer',
                      color:
                        id !== useFor ? theme.palette.common.black : theme.palette.primary.main,
                    }}
                  >
                    <Icon />
                    <Typography>{label}</Typography>
                  </Box>
                ))}
              </Box>
              <Box>
                <LoadingButton
                  variant="contained"
                  onClick={() => handleContinue(true)}
                  disabled={!useFor}
                  loading={isCreating}
                >
                  {t('button.continue')}
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box flex={0.4}>
        <EmptyCustomerSvg />
      </Box>
    </Box>
  );
}

export default EmptyCustomer;
