import React from 'react';

import AdminLayout from 'src/layout/admin_layout';
import AuthLayout from 'src/layout/auth_layout';

const NotFound = React.lazy(() => import('src/components/not_found'));
const Login = React.lazy(() => import('src/pages/login'));
const Watchlist = React.lazy(() => import('src/pages/watchlist'));
const StationDetail = React.lazy(() => import('src/pages/station_detail'));
const CustomerDetail = React.lazy(() => import('src/pages/customer_management_new'));
const EmptyCustomer = React.lazy(() => import('src/pages/empty_customer'));
const StationMap = React.lazy(() => import('src/pages/station_map'));
// const CameraMap = React.lazy(() => import('src/pages/camera_map'));
// const Register = React.lazy(() => import('src/pages/register'));
// const CameraManagement = React.lazy(() => import('src/pages/camera_management'));
// const UserManagement = React.lazy(() => import('src/pages/user_management'));
// const StationManagement = React.lazy(() => import('src/pages/station_management'));
// const SystemManagement = React.lazy(() => import('src/pages/system_management'));
// const CustomerDetailOld = React.lazy(() => import('src/pages/customer_management/customer_detail'));
// const OrderDetail = React.lazy(
//   () => import('src/pages/customer_management/customer_detail/orders/order_detail'),
// );
// const RoleDetail = React.lazy(() => import('src/pages/user_management/roles/role_detail'));
// const MemberDetailManagement = React.lazy(
//   () => import('src/pages/user_management/members/member_detail'),
// );
// const NotificationManagement = React.lazy(() => import('src/pages/notification'));

import { EPermissions } from 'src/types/enum';
import { IRoute } from 'src/types/route';
import { PATH } from './path';

export const ROUTE_LIST: IRoute[] = [
  {
    path: PATH.stationMap,
    name: 'routes.stationMap',
    component: StationMap,
    layout: AdminLayout,
    permission: [EPermissions.LIST_ORDER_RESOURCE],
    isHaveRoute: true,
  },
  {
    path: PATH.stationDetail,
    name: 'routes.stationDetail',
    component: StationDetail,
    layout: AdminLayout,
    permission: [EPermissions.LIST_ORDER_RESOURCE],
    isHaveRoute: true,
  },
  {
    path: PATH.watchList,
    name: 'routes.watchList',
    component: Watchlist,
    layout: AdminLayout,
    permission: [EPermissions.LIST_ORDER_RESOURCE],
    isHaveRoute: true,
  },
  {
    path: PATH.login,
    name: 'routes.login',
    component: Login,
    layout: AuthLayout,
    isHaveRoute: true,
  },
  {
    path: PATH.addCustomer,
    name: '',
    layout: AdminLayout,
    component: EmptyCustomer,
    isHaveRoute: true,
  },
  {
    path: PATH.customerDetail,
    name: 'routes.customerManagement',
    component: CustomerDetail,
    layout: AdminLayout,
    isHaveRoute: true,
    permission: [EPermissions.UPDATE_CUSTOMER],
  },
  // {
  //   path: PATH.cameraMap,
  //   name: 'routes.cameraMap',
  //   component: CameraMap,
  //   layout: AdminLayout,
  //   permission: [EPermissions.LIST_ORDER_RESOURCE],
  //   isHaveRoute: true,
  // },
  // {
  //   path: PATH.stationManagement,
  //   name: 'routes.stationManagement',
  //   component: StationManagement,
  //   layout: AdminLayout,
  //   permission: [EPermissions.GET_LIST_STATION],
  //   isHaveRoute: true,
  // },
  // {
  //   path: PATH.cameraManagement,
  //   name: 'routes.settingVideo',
  //   component: CameraManagement,
  //   layout: AdminLayout,
  //   permission: [EPermissions.GET_LIST_CAMERA],
  //   isHaveRoute: true,
  // },
  // {
  //   path: PATH.userManagement,
  //   name: 'routes.usersManagement',
  //   component: UserManagement,
  //   layout: AdminLayout,
  //   permission: [EPermissions.LIST_ORDER_MEMBER],
  //   isHaveRoute: true,
  // },
  // {
  //   path: PATH.roleDetail,
  //   name: 'routes.roleDetail',
  //   component: RoleDetail,
  //   layout: AdminLayout,
  //   permission: [EPermissions.LIST_ORDER_MEMBER],
  //   isHaveRoute: true,
  // },
  // {
  //   path: PATH.userDetailManagement,
  //   name: 'routes.usersManagement',
  //   component: MemberDetailManagement,
  //   layout: AdminLayout,
  //   permission: [EPermissions.LIST_ORDER_MEMBER],
  //   isHaveRoute: true,
  // },

  // {
  //   path: PATH.register,
  //   name: 'routes.userRegistration',
  //   component: Register,
  //   layout: AuthLayout,
  //   isHaveRoute: true,
  // },
  // {
  //   path: PATH.systemManagement,
  //   name: 'routes.systemManagement',
  //   component: SystemManagement,
  //   layout: AdminLayout,
  //   isHaveRoute: true,
  // },
  // {
  //   path: PATH.notification,
  //   name: 'routes.notification',
  //   component: NotificationManagement,
  //   layout: AdminLayout,
  //   isHaveRoute: true,
  // },
  // {
  //   path: PATH.customerDetailOld,
  //   name: 'common.routes.customerManagement',
  //   component: CustomerDetailOld,
  //   layout: AdminLayout,
  //   isHaveRoute: true,
  //   permission: [EPermissions.UPDATE_CUSTOMER],
  // },
  // {
  //   path: PATH.customerOrder,
  //   name: 'routes.customerManagement',
  //   component: OrderDetail,
  //   layout: AdminLayout,
  //   isHaveRoute: true,
  //   permission: [EPermissions.UPDATE_CUSTOMER],
  // },
  {
    path: PATH.notFound,
    name: 'routes.notFound',
    component: NotFound,
    isHaveRoute: true,
  },
];
