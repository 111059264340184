export const MODAL_IDS = {
  addPermissionGroup: 'addPermissionGroup',
  updatePermissionGroup: 'updatePermissionGroup',
  confirmDelete: 'confirmDelete',

  inviteUser: 'inviteUser',
  addFollowGroup: 'addFollowGroup',
  notificationSetting: 'notificationSetting',
  deleteNotification: 'deleteNotification',
  addUser: 'addUser',
  deleteUser: 'deleteUser',
  relayPassword: 'relayPassword',
  changeAvatar: 'changeAvatar',

  notifyUserWait: 'notifyUserWait',

  configStation: 'configStation',

  assignStation: 'assignStation',

  franchise: 'franchise',

  player: {
    exportVideo: 'exportVideo',
  },
  customer: {
    add: 'add',
    edit: 'edit',
    orders: {
      add: 'add',
      addCamera: 'addCamera',
      updateCamera: 'updateCamera',
      addStation: 'addStation',
      updateStation: 'updateStation',
      addMembers: 'addMembers',
      addOrderMembers: 'addOrderMembers',
      addRoles: 'addRoles',
    },
  },

  unmountConfirm: 'unmountConfirm',

  logout: 'logout',

  reloadPage: 'reloadPage',
  autoLogout: 'autoLogout',

  openAppDownloadModal: 'openAppDownloadModal',
};
