import { useEffect, useState } from 'react';
import { signaling } from 'src/components/player_rtc/controller/signaling';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { setWsReconnection } from 'src/redux_store/stream/stream_slice';

export const useSignaling = () => {
  const dispatch = useAppDispatch();
  const { wsReconnect, hasOnline } = useAppSelector(({ streamSlice }) => streamSlice);
  const [wsOpen, setWsOpen] = useState(false);

  // const online = navigator.onLine;

  // const [hasOnline, setHasOnline] = useState(navigator.onLine || true);

  useEffect(() => {
    signaling.connect(false);
    // signalingReconnect();
    if (signaling.ws) {
      signaling.ws.onopen = () => {
        setWsOpen(true);
        signaling.wsAuth();
      };

      signaling.ws.onclose = () => {
        console.log('useEffect ws onclose');
        setWsOpen(false);
      };
    }

    return () => {
      signaling.close();
      setWsOpen(false);
      dispatch(setWsReconnection(false));
    };
  }, []);

  useEffect(() => {
    if (wsReconnect) {
      signaling.connect(false);
      if (signaling.ws) {
        signaling.ws.onopen = () => {
          setWsOpen(true);
          signaling.wsAuth();
          dispatch(setWsReconnection(false));
        };

        signaling.ws.onclose = () => {
          console.log('ws onclose');
          setWsOpen(false);
        };
      }
    }
  }, [wsReconnect]);

  useEffect(() => {
    console.log({ hasOnline });
    if (hasOnline !== navigator.onLine) {
      console.log('Mang da thay doi');
    }
  });

  // useEffect(() => {
  //   console.log({ hasOnline, online1: navigator.onLine, online });
  //   if (hasOnline !== navigator.onLine) {
  //     console.log('use Signaling', navigator.onLine);
  //     // if (!navigator.onLine) return signaling.close();
  //   }
  // }, [hasOnline, online]);

  return wsOpen;
};
