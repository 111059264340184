import { AddOutlined, GroupsOutlined, KeyboardArrowDown, Logout } from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { MODAL_IDS } from 'src/constants/modal';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { closeModal, openModal } from 'src/redux_store/common/modal/modal_slice';
import {
  closeBoxRightContent,
  openBoxRightContent,
} from 'src/redux_store/common/topbar/topbar_slice';
import { getPackagesByProductId } from 'src/redux_store/customer/product_packages/actions';
import { getProducts } from 'src/redux_store/customer/products/actions';
import {
  getMyOrderRoles,
  getMyOrders,
  logout,
  updateUserLocale,
} from 'src/redux_store/my_account/my_account_action';
import { changeIsReload, logoutLocal } from 'src/redux_store/my_account/my_account_slice';
import { markAllAsSeenNotification } from 'src/redux_store/notification/notification_action';
import { setNumberAndInfoOfNotification } from 'src/redux_store/notification/notification_slice';
import { PATH } from 'src/routes/path';
import { INotification } from 'src/types/notification';
import { syncNumberOfNotification } from 'src/utils/notification';
import { toastMessage } from 'src/utils/toast';
import { useStyles } from './styles';

import logo from 'src/assets/images/logo.png';
import config from 'src/config';
import { LOCALE_LANGUAGE_LIST } from 'src/constants/i18n';
import TOP_BAR_LIST from 'src/constants/topbar';
import AddCustomerModal from 'src/pages/customer_management_new/customer_add';
import theme from 'src/theme';
import en from '../../assets/images/en-us-flag-small.png';
import vi from '../../assets/images/vi-flag-small.png';
import ConfirmationDialog from '../modal/confirm_dialog';
import IconButtonTooltip from '../tooltip/icon_button_tooltip';

interface IProps {
  title: string;
}

const Topbar = ({ title }: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { me, customerId, customers, customer } = useAppSelector((state) => state.myAccountSlice);
  const { indexActive } = useAppSelector((state) => state.topBarSlice);
  const { numberOfNotifications } = useAppSelector((state) => state.notificationSlice);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const [localeSelected, setLocaleSelected] = useState<string>('vi');

  // const LOCALE_LANGUAGE_LIST = [
  //   {
  //     id: 'vi',
  //     name: t('label.language.vi'),
  //     flag: VIFlag,
  //   },
  //   {
  //     id: 'en',
  //     name: t('label.language.en'),
  //     flag: EN_US_Flag,
  //   },
  // ];

  const [open, setOpen] = React.useState<{
    [x: string]: boolean;
  }>({});

  useEffect(() => {
    const locale = me.locale || 'vi';
    setLocaleSelected(locale);
    moment.locale(locale);
  }, [me.locale]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>, name: string) => {
    setAnchorEl(event.currentTarget);
    setOpen({ [name]: true });
  };

  const handleClose = (name: string) => {
    setAnchorEl(null);
    setOpen({ [name]: false });
  };

  const handleOpenModal = () => {
    dispatch(
      openModal({
        modalId: MODAL_IDS.logout,
        dialogComponent: (
          <ConfirmationDialog
            modalId={MODAL_IDS.logout}
            icon={<Logout fontSize="large" color="primary" />}
            describe={t('label.logout.description')}
            callback={() => {
              dispatch(changeIsReload(false));

              dispatch(logout())
                .unwrap()
                .finally(() => {
                  dispatch(logoutLocal());
                  dispatch(closeModal({ modalId: MODAL_IDS.logout }));
                  navigation(PATH.login);
                  dispatch(changeIsReload(true));
                });
            }}
            functionName="logout"
            sliceName="myAccount"
          />
        ),
      }),
    );
  };

  // const handleClickCustomerUser = () => {
  //   if (statusGlobal === 'pending') return;

  //   if (!(role?.permissions || []).includes(EPermissions.UPDATE_CUSTOMER)) {
  //     return navigation(PATH.stationMap, { replace: true });
  //   }

  //   if (!customerId || !customer) return;

  //   return navigation(`${PATH.customerManagement}/${customerId}?customerName=${customer.name}`);
  // };

  const handleClickTopBarIcon = (index: number, typeId: string) => {
    if (typeId === 'notification') {
      syncNumberOfNotification(me.id);
      dispatch(
        setNumberAndInfoOfNotification({
          numberNotification: 0,
          data: {} as INotification,
        }),
      );
      dispatch(markAllAsSeenNotification());
    }

    dispatch(openBoxRightContent({ index, typeId }));
  };

  const handleClickTopBarActiveIcon = () => {
    if (location.pathname !== PATH.notification) dispatch(closeBoxRightContent());
  };

  const renderIconTopBar = () => {
    return TOP_BAR_LIST.map((icon, index) => {
      const Icon = icon.icon;
      const ActiveIcon = icon.activeIcon;

      return (
        <Grid item key={index}>
          {indexActive === index || `/admin/${icon.id}` === location.pathname ? (
            <IconButtonTooltip
              title={t(`common.topbar.${icon.id}`)}
              icon={
                icon.id === 'notification' ? (
                  <Badge badgeContent={numberOfNotifications} color="error">
                    <ActiveIcon fontSize="medium" color="primary" />
                  </Badge>
                ) : (
                  <ActiveIcon fontSize="medium" color="primary" />
                )
              }
              size="medium"
              style={{ color: theme.palette.secondary.main }}
              onClick={handleClickTopBarActiveIcon}
            />
          ) : (
            <IconButtonTooltip
              title={t(`common.topbar.${icon.id}`)}
              icon={
                icon.id === 'notification' ? (
                  <Badge badgeContent={numberOfNotifications} color="error">
                    <Icon fontSize="medium" />
                  </Badge>
                ) : (
                  <Icon fontSize="medium" />
                )
              }
              size="medium"
              style={{ color: theme.palette.secondary.main }}
              onClick={() => handleClickTopBarIcon(index, icon.id)}
            />
          )}
        </Grid>
      );
    });
  };

  const getRolesByOrderId = (orderId: string) => {
    localStorage.setItem('order', orderId);
    dispatch(getMyOrderRoles(orderId));
  };

  const onChangeCustomer = (value: string) => {
    if (value == customerId) return;

    navigation(`/customers/${value}/stations`, { replace: true, state: 'stations' });

    localStorage.setItem('customer_order', value);

    dispatch(getMyOrders(value))
      .unwrap()
      .then((data) => {
        if (data.length) {
          getRolesByOrderId(data[0].order.id);
        }
      });

    handleClose('customerList');
  };

  const handleAddCustomer = () => {
    dispatch(getProducts())
      .unwrap()
      .then((products) => {
        dispatch(getPackagesByProductId(products[0]?.id));
      })
      .catch((error) => toastMessage.error(error?.message));

    dispatch(
      openModal({
        modalId: MODAL_IDS.customer.add,
        dialogComponent: <AddCustomerModal modalId={MODAL_IDS.customer.add} />,
      }),
    );
  };

  // const onChangeOrder = (e: SelectChangeEvent<string>) => {
  //   if (e.target.value && e.target.value !== orderId) {
  //     navigation(PATH.stationMap, { replace: true });

  //     getRolesByOrderId(e.target.value);
  //   }
  // };

  // const stringAvatar = (name: string) => {
  //   return {
  //     children: `${name.split(' ')[0][0]}`,
  //   };
  // };

  const handleChangeLocale = (locale: string) => {
    if (locale === localeSelected) return;
    dispatch(updateUserLocale({ userId: me.id, data: { locale } }))
      .unwrap()
      .then(() => {
        localStorage.setItem('i18nextLng', locale);
        window.location.reload();
      });
  };

  return (
    <Box className={classes.root}>
      <Box display="flex" flexWrap="nowrap" flex={1}>
        <Box className={classes.logo} onClick={() => navigation(PATH.stationMap)}>
          <img
            src={logo}
            style={{
              height: '100%',
              objectFit: 'contain',
              verticalAlign: 'middle',
              cursor: 'pointer',
            }}
            alt="danateq.vn"
          />
        </Box>

        <Box className={classes.title}>
          <Typography fontWeight={600} variant="body1" textTransform="uppercase">
            {title}
          </Typography>
        </Box>
      </Box>

      <Box className={classes.buttonAction}>
        {customers.length ? (
          <Box>
            <Button
              startIcon={<GroupsOutlined />}
              endIcon={<KeyboardArrowDown />}
              color="inherit"
              onClick={(e) => handleOpen(e, 'customerList')}
              title={customer?.name}
            >
              <Typography noWrap width={100} fontWeight={600} textAlign="left">
                {customer?.name}
              </Typography>
            </Button>
            <Popover
              id={'customerList'}
              open={Boolean(open?.customerList)}
              anchorEl={anchorEl}
              onClose={() => handleClose('customerList')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuList sx={{ minWidth: 120, maxWidth: 200 }}>
                {customers.map((customer) => {
                  return (
                    <MenuItem
                      key={customer.id}
                      selected={customer.id === customerId}
                      onClick={() => onChangeCustomer(customer.id as string)}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      title={customer.name}
                    >
                      <Typography variant="inherit" noWrap>
                        {customer.name}
                      </Typography>
                    </MenuItem>
                  );
                })}
                {customers.length && (
                  <MenuItem
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                    onClick={handleAddCustomer}
                  >
                    <Typography>{t('label.add')}</Typography>
                    <AddOutlined />
                  </MenuItem>
                )}
              </MenuList>
            </Popover>
          </Box>
        ) : null}

        <Grid sx={{ width: 'fit-content' }} container>
          <Grid item>
            <Box onClick={(e) => handleOpen(e, 'userLanguage')} sx={{ cursor: 'pointer' }}>
              <img
                src={localeSelected === 'vi' ? vi : en}
                alt="locate"
                style={{
                  width: '32px',
                  height: '32px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                }}
              />
            </Box>
            <Popover
              id={'userLanguage'}
              open={Boolean(open?.userLanguage)}
              anchorEl={anchorEl}
              onClose={() => handleClose('userLanguage')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuList>
                {LOCALE_LANGUAGE_LIST(t).map((locale) => {
                  return (
                    <MenuItem
                      onClick={() => handleChangeLocale(locale.id)}
                      selected={locale.id === localeSelected}
                      key={locale.id}
                    >
                      <Box display={'flex'} gap={1.5} alignItems={'center'}>
                        <img width={'25px'} alt="Danapod" src={locale.flag} />
                        <Typography>{locale.name}</Typography>
                      </Box>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Popover>
          </Grid>
          {renderIconTopBar()}
        </Grid>

        <Box>
          <Box sx={{ cursor: 'pointer' }} onClick={(e) => handleOpen(e, 'userSetting')}>
            <img
              src={me.avatarUrl}
              width={'40px'}
              height={'40px'}
              style={{ borderRadius: '50%', objectFit: 'cover' }}
              alt=""
            />
          </Box>
          <Menu
            id="userSetting"
            open={Boolean(open?.userSetting)}
            anchorEl={anchorEl}
            elevation={2}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={() => handleClose('userSetting')}
          >
            <Box sx={{ width: '320px', padding: '0 5px' }}>
              <Box width={'100%'} mt={1} display={'flex'} alignItems={'center'}>
                <Box
                  sx={{
                    position: 'relative',
                    width: '80px',
                    height: '80px',
                    ml: 1,
                  }}
                >
                  <img
                    src={me.avatarUrl}
                    width={'80px'}
                    height={'80px'}
                    style={{ objectFit: 'cover', borderRadius: '50%', position: 'absolute' }}
                    alt=""
                  />
                </Box>
                <Box
                  p={0.5}
                  ml={1}
                  sx={{
                    width: '220px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'600'}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {me.name}
                  </Typography>
                  <Typography>{me.email}</Typography>
                  <a href={`${config.accountWebApp}/login`} style={{ color: '#3f50b5' }}>
                    {t('label.myAccount')}
                  </a>
                </Box>
              </Box>

              <Box
                onClick={handleOpenModal}
                flex={1}
                height={'30px'}
                display={'flex'}
                textAlign={'center'}
                alignItems={'center'}
                sx={{
                  gap: '15px',
                  padding: '15px 10px',
                  cursor: 'pointer',
                  mt: 1,
                  '&:hover': { backgroundColor: 'rgba(0,0,0,0.08)' },
                }}
              >
                <Box
                  height={'30px'}
                  sx={{
                    display: 'grid',
                    placeItems: 'center',
                  }}
                >
                  <Logout sx={{ fontSize: '25px' }} />
                </Box>
                <Box
                  height={'30px'}
                  sx={{
                    cursor: 'pointer',
                    display: 'grid',
                    placeItems: 'center',
                  }}
                >
                  <Typography>{t('label.logout.main')}</Typography>
                </Box>
              </Box>
            </Box>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
