import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IAddOrderMembersRequest,
  IInviteOrderMemberRequest,
  IOrderMember,
  IResendInviteOrderMemberRequest,
} from 'src/types/customer';
import client from 'src/clients/http';
import { toastMessage } from 'src/utils/toast';
import { MODAL_IDS } from 'src/constants/modal';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import i18n from 'src/i18n';

export const addOrderMembers = createAsyncThunk<IOrderMember[], IAddOrderMembersRequest>(
  'orderMembers/addOrderMembers',
  async (payload, { rejectWithValue }) => {
    try {
      const members = await client.addOrderMembers(payload);
      return members;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const inviteOrderMember = createAsyncThunk<IOrderMember, IInviteOrderMemberRequest>(
  'orderMembers/inviteOrderMember',
  async (payload, { rejectWithValue }) => {
    try {
      const orderMember = await client.inviteOrderMember(payload);
      toastMessage.success('Mời người dùng thành công');
      return orderMember;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const resendInviteOrderMember = createAsyncThunk<void, IResendInviteOrderMemberRequest>(
  'orderMembers/resendInviteOrderMember',
  async (payload, { rejectWithValue }) => {
    try {
      await client.resendInviteOrderMember(payload);
      toastMessage.success('Gửi lại lời mời thành công');

      return;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getOrderMembers = createAsyncThunk<
  IOrderMember[],
  { customerId: string; orderId: string }
>('orderMembers/getOrderMembers', async ({ customerId, orderId }, { rejectWithValue }) => {
  try {
    const members = await client.getOrderMembers(customerId, orderId);
    return members;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getOrderMembersNotBelongOrder = createAsyncThunk<
  IOrderMember[],
  { customerId: string; orderId: string }
>(
  'orderMembers/getOrderMembersNotBelongOrder',
  async ({ customerId, orderId }, { rejectWithValue }) => {
    try {
      const members = await client.getOrderMembersNotBelongOrder(customerId, orderId);
      return members;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const deleteOrderMember = createAsyncThunk<
  string,
  { customerId: string; orderId: string; memberId: string }
>(
  'orderMembers/deleteOrderMember',
  async ({ customerId, orderId, memberId }, { rejectWithValue, dispatch }) => {
    try {
      await client.deleteOrderMember(customerId, orderId, memberId);

      toastMessage.success(i18n.t('message.deleteUserFromOrder.success'));
      dispatch(closeModal({ modalId: MODAL_IDS.confirmDelete }));
      return memberId;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);
