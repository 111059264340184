import { createSlice } from '@reduxjs/toolkit';
import { getCamerasOfConfigs } from 'src/redux_store/camera/camera_action';
import { getStations } from 'src/redux_store/station/station_action';
import { getOrderMemberCameras, getOrderMemberStations } from 'src/redux_store/user/user_actions';
import { ICamera } from 'src/types/camera';
import { IOrderMember } from 'src/types/customer';
import { IStation } from 'src/types/station';

import {
  addOrderMembers,
  deleteOrderMember,
  getOrderMembers,
  getOrderMembersNotBelongOrder,
  inviteOrderMember,
} from './actions';

interface IPayloadTransfer {
  payload: {
    fieldFromTransfer: 'assignedCameras' | 'cameras' | 'assignedStations' | 'stations';
    fieldToTransfer: 'assignedCameras' | 'cameras' | 'assignedStations' | 'stations';
    dataTransfer: ICamera[] | IStation[];
  };
}

interface IDataState {
  assignedCameras: ICamera[];
  cameras: ICamera[];

  assignedStations: IStation[];
  stations: IStation[];
}

interface IOrderMembersState {
  data: IDataState;
  memberNotBelongOrder: IOrderMember[];
  members: IOrderMember[];
  memberDetail: IOrderMember | null;
  trackAllCameras: boolean;
  trackAllStations: boolean;
}

const initialState: IOrderMembersState = {
  memberNotBelongOrder: [],
  members: [],
  memberDetail: null,
  data: {
    assignedCameras: [],
    assignedStations: [],

    cameras: [],
    stations: [],
  },
  trackAllCameras: false,
  trackAllStations: false,
};

const orderMembers = createSlice({
  name: 'orderMembers',
  initialState,
  reducers: {
    setOrderMember: (state, action) => {
      state.memberDetail = action.payload;
    },
    transferData: (state, action: IPayloadTransfer) => {
      const { fieldFromTransfer, fieldToTransfer, dataTransfer } = action.payload;
      const cloneDataList = [...state.data[fieldFromTransfer as keyof IDataState]];

      dataTransfer.map((item: any) => {
        const index = cloneDataList.findIndex((data: any) => data.id === item.id);
        cloneDataList.splice(index, 1);
      });

      const cloneDataListSelected = [...state.data[fieldToTransfer as keyof IDataState]];
      const newDataSelectedList = cloneDataListSelected.concat(dataTransfer as any);

      state.data[fieldFromTransfer as keyof IDataState] = cloneDataList as any[];
      state.data[fieldToTransfer as keyof IDataState] = newDataSelectedList as any[];
    },
    changeTrackAll: (
      state,
      action: { payload: { type: 'trackAllCameras' | 'trackAllStations'; checked: boolean } },
    ) => {
      state[action.payload.type] = action.payload.checked;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addOrderMembers.fulfilled, (state, action) => {
        state.members.push(...action.payload);
      })
      .addCase(inviteOrderMember.fulfilled, (state, action) => {
        state.members.push(action.payload);
      })
      .addCase(getOrderMembers.fulfilled, (state, action) => {
        const activeMembers = action.payload.filter((item) => item.status === 'accepted');
        const pendingMembers = action.payload.filter((item) => item.status === 'pending');
        const rejectedMembers = action.payload.filter((item) => item.status === 'rejected');
        const deletedMembers = action.payload.filter((item) => item.status === 'deleted');

        state.members = activeMembers.concat(pendingMembers, rejectedMembers, deletedMembers);
      })
      .addCase(getOrderMembersNotBelongOrder.fulfilled, (state, action) => {
        state.memberNotBelongOrder = action.payload;
      })
      .addCase(deleteOrderMember.fulfilled, (state, action) => {
        state.members = state.members.filter((member) => member.orderMemberId !== action.payload);
      })
      .addCase(getCamerasOfConfigs.fulfilled, (state, action) => {
        const newCameras = action.payload.filter(
          (camera) =>
            !state.data.assignedCameras.some((cameraAssigned) => cameraAssigned.id === camera.id),
        );

        state.data.cameras = newCameras;
      })
      .addCase(getStations.fulfilled, (state, action) => {
        const newStations = action.payload.filter(
          (station) =>
            !state.data.assignedStations.some(
              (stationAssigned) => stationAssigned.id === station.id,
            ),
        );
        state.data.stations = newStations;
      })
      .addCase(getOrderMemberCameras.fulfilled, (state, action) => {
        state.trackAllCameras = action.payload.trackAllCameras;
        state.data.assignedCameras = action.payload.cameras;
      })
      .addCase(getOrderMemberStations.fulfilled, (state, action) => {
        state.trackAllStations = action.payload.trackAllStations;
        state.data.assignedStations = action.payload.stations;
      });
  },
});

const { reducer, actions } = orderMembers;
export const { setOrderMember, transferData, changeTrackAll } = actions;
export default reducer;
