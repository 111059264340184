import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import vi from './vi.json';
import en from './en.json';
import Cookies from 'js-cookie';

const lng = Cookies.get('locale') || localStorage.getItem('i18nextLng') || 'en';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: lng,
    fallbackLng: lng,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      vi: {
        translation: vi,
      },
      en: {
        translation: en,
      },
    },
  });

export default i18n;
