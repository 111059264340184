import { createSlice } from '@reduxjs/toolkit';

import { IStation } from 'src/types/station';
import { getOrders } from '../orders/actions';
import {
  addOrderStations,
  deleteOrderStation,
  getStationWithoutCustomer,
  getOrderStations,
} from './actions';

interface IOrderStationState {
  stationsWithoutCustomer: IStation[];
  stations: IStation[];
}

const initialState: IOrderStationState = {
  stationsWithoutCustomer: [],
  stations: [],
};

const orderStationSlice = createSlice({
  name: 'orderCameras',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addOrderStations.fulfilled, (state, action) => {
        state.stations.push(...action.payload);
      })
      .addCase(getStationWithoutCustomer.fulfilled, (state, action) => {
        state.stationsWithoutCustomer = action.payload;
      })
      .addCase(getOrderStations.fulfilled, (state, action) => {
        state.stations = action.payload;
      })

      .addCase(deleteOrderStation.fulfilled, (state, action) => {
        state.stations = state.stations.filter((camera) => camera.id !== action.payload);
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        if (!action.payload.length) {
          state.stations.length = 0;
        }
      });
  },
});

const { reducer } = orderStationSlice;

export default reducer;
