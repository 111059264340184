import config from 'src/config';
import store from 'src/redux_store';
import {
  changeCameraStatus,
  deleteCameraLocal,
  removeCamerasByListCameraId,
} from 'src/redux_store/camera/camera_slice';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import {
  addStationWhenHaveSocketInMap,
  deleteCameraInMap,
  deleteStationLocalInMap,
  stationBatteryDisconnectedInMap,
  stationBatteryUpdatedInMap,
  stationGPSConnectedInMap,
  stationGpsDisconnectedInMap,
  stationMobileNetworkDisconnectedInMap,
  stationMobileNetworkUpdatedInMap,
  updateCameraStatusInMap,
  updateLightStatus,
  updateStationStatusInMap,
  updateStationWorkingModeSocketInMap,
} from 'src/redux_store/map/map_slice';
import {
  changeIsAutoLogoutEventSocket,
  changeIsReloadPageEventSocket,
  updateCustomers,
} from 'src/redux_store/my_account/my_account_slice';
import { getStationGeneral } from 'src/redux_store/station/station_action';
import { removeWatchListPositionByListCameraId } from 'src/redux_store/watchlist/watchlist_slice';
import { PATH } from 'src/routes/path';
import { ECameraStatus } from 'src/types/camera';
import { EGpsStatus, ENotificationEvent } from 'src/types/enum';
import {
  IStationBatteryUpdated,
  IStationNetworkUpdated,
  IStationUpdateStatus,
  IUpdateStationGPS,
} from 'src/types/notification';
import {
  ELightStatus,
  EMobileNetworkStatus,
  EPowerBatteryStatus,
  EStationStatus,
  IStation,
} from 'src/types/station';
import { TAutoLogout } from 'src/types/user';

const MaxWebsocketFails = 7;
const MinWebsocketRetryTime = 3000; // 3 sec
const MaxWebsocketRetryTime = 300000; // 5 minutes

export default class WebSocketClient {
  conn: WebSocket | null;
  //   private connectionUrl: string | null;
  private connectFailCount: number;
  private timerKeepConnectServer: any;

  constructor() {
    this.conn = null;
    // this.connectionUrl = null;
    this.connectFailCount = 0;
    this.timerKeepConnectServer = null;
  }

  initialize(token?: string) {
    if (this.conn) {
      return;
    }

    this.conn = new WebSocket(config.notificationUrl);

    this.conn.onopen = () => {
      console.log('[WS] websocket open ==> ', token);
      if (token) {
        this.sendMessage({ data: { token }, action: 'auth' });
      }
    };

    this.conn.onclose = () => {
      this.conn = null;

      if (this.timerKeepConnectServer) {
        clearInterval(this.timerKeepConnectServer);
      }
      if (this.connectFailCount === 0) {
        console.log('[WS] websocket closed');
      }

      this.connectFailCount++;

      let retryTime = MinWebsocketRetryTime;
      if (this.connectFailCount > MaxWebsocketFails) {
        retryTime = MinWebsocketRetryTime * this.connectFailCount * this.connectFailCount;
        if (retryTime > MaxWebsocketRetryTime) {
          retryTime = MaxWebsocketRetryTime;
        }
      }

      retryTime += Math.random() * 2000;

      setTimeout(() => {
        this.initialize(token);
      }, retryTime);
    };

    this.ping();

    this.conn.onerror = (event) => {
      if (this.connectFailCount <= 1) {
        console.log('[WS websocket error');
        console.log(event);
      }
    };

    this.conn.onmessage = (event) => {
      const dataJSON: { event: ENotificationEvent; data: any } = JSON.parse(event.data);
      const { event: typeEvent, data } = dataJSON;

      const dispatch = store.dispatch;

      console.log({ typeEvent });

      switch (typeEvent) {
        // case ENotificationEvent.STATION_DISCONNECTED:
        //   this.updateStationStatus(
        //     {
        //       stationId: data.stationId,
        //       status: EStationStatus.ERROR,
        //       timestamp: data.timestamp,
        //     },
        //     dispatch,
        //   );
        //   break;

        // case ENotificationEvent.STATION_CONNECTED:
        //   this.updateStationStatus(
        //     {
        //       stationId: data.stationId,
        //       status: EStationStatus.NORMAL,
        //       timestamp: data.timestamp,
        //     },
        //     dispatch,
        //   );
        //   break;

        // case ENotificationEvent.STATION_ENABLED:
        //   this.updateStationStatus(
        //     {
        //       stationId: data.stationId,
        //       status: EStationStatus.NORMAL,
        //       timestamp: data.timestamp,
        //     },
        //     dispatch,
        //   );
        //   break;

        // case ENotificationEvent.STATION_DISABLED:
        //   this.updateStationStatus(
        //     {
        //       stationId: data.stationId,
        //       status: EStationStatus.OFFLINE,
        //       timestamp: data.timestamp,
        //     },
        //     dispatch,
        //   );
        //   break;

        case ENotificationEvent.STATION_STATUS_UPDATED:
          this.updateStationStatus(
            {
              stationId: data.stationId,
              status: data.status || EStationStatus.OFFLINE,
              timestamp: data.timestamp,
            },
            dispatch,
          );
          break;

        case ENotificationEvent.STATION_GPS_DISCONNECTED:
          this.stationGPSDisconnect(data, dispatch);
          break;

        case ENotificationEvent.STATION_GPS_UPDATED:
          this.stationGPSConnected(data, dispatch);
          break;

        case ENotificationEvent.STATION_BATTERY_DISCONNECTED:
          this.stationBatteryDisconnected(data, dispatch);
          break;

        case ENotificationEvent.STATION_BATTERY_UPDATED:
          this.batteryUpdated(data, dispatch);
          break;

        case ENotificationEvent.STATION_MOBILE_NETWORK_DISCONNECTED:
          this.stationMobileNetworkDisconnected(data, dispatch);
          break;

        case ENotificationEvent.STATION_MOBILE_NETWORK_UPDATED:
          this.mobileNetworkUpdate(data, dispatch);
          break;

        case ENotificationEvent.STATION_LIGHT_TURN_ON:
          dispatch(
            updateLightStatus({
              stationId: data.stationId,
              status: ELightStatus.ON,
            }),
          );
          break;

        case ENotificationEvent.STATION_LIGHT_TURN_OFF:
          dispatch(
            updateLightStatus({
              stationId: data.stationId,
              status: ELightStatus.OFF,
            }),
          );
          break;

        case ENotificationEvent.STATION_DELETED:
          // dispatch(deleteStationLocalInMap(data.stationId));

          this.deleteStation(
            { stationId: data.stationId, cameraIds: data.cameraIds || [] },
            dispatch,
          );
          // this.reloadPage(dispatch);

          break;
        case ENotificationEvent.STATION_WORKING_MODE_UPDATED:
          dispatch(
            updateStationWorkingModeSocketInMap({
              stationId: data.stationId,
              timestamp: data.timestamp,
              newWorkingMode: {
                workingMode: data.workingMode,
                workingSchedule: data.workingSchedule,
              },
            }),
          );
          break;

        //camera
        case ENotificationEvent.CAMERA_CONNECTED:
          this.cameraUpdateStatus(data, ECameraStatus.NORMAL, dispatch);
          break;
        case ENotificationEvent.CAMERA_DISCONNECTED:
          this.cameraUpdateStatus(data, ECameraStatus.ERROR, dispatch);
          break;
        case ENotificationEvent.CAMERA_ENABLED:
          this.cameraUpdateStatus(data, ECameraStatus.NORMAL, dispatch);
          break;
        case ENotificationEvent.CAMERA_DISABLED:
          this.cameraUpdateStatus(data, ECameraStatus.OFFLINE, dispatch);
          break;
        case ENotificationEvent.CAMERA_DELETED:
          this.cameraDeleted(data.cameraId, dispatch);
          // this.reloadPage(dispatch);

          break;

        //customer
        case ENotificationEvent.CUSTOMER_DELETED:
          this.reloadPage(dispatch, data.customerId);
          break;
        case ENotificationEvent.ORDER_DELETED:
          this.reloadPage(dispatch, data.customerId);
          break;
        case ENotificationEvent.ORDER_MEMBER_DELETED:
          this.reloadPage(dispatch, data.customerId);
          break;
        case ENotificationEvent.ORDER_MEMBER_ROLE_UPDATED:
          this.reloadPage(dispatch, data.customerId, true);
          break;

        case ENotificationEvent.ORDER_STATION_DELETED:
          this.deleteStation(
            {
              stationId: data.stationId,
              cameraIds: data.cameraIds,
            },
            dispatch,
          );
          break;

        case ENotificationEvent.ORDER_STATION_ADDED:
          this.orderStationAdded(data, dispatch);
          break;

        case ENotificationEvent.ORDER_MEMBER_STATION_UPDATED:
          console.log('ENotificationEvent.ORDER_MEMBER_STATION_UPDATED');

          this.reloadPage(dispatch, data.customerId, true);
          break;

        case ENotificationEvent.USER_REVOKED_SESSION:
          this.autoLogoutUser('user_revoked_session', dispatch);
          break;
        default:
          break;
      }
    };
  }

  close = () => {
    this.connectFailCount = 0;
    if (this.conn && this.conn.readyState === WebSocket.OPEN) {
      this.conn.onclose = () => {};
      this.conn.close();
      this.conn = null;
      console.log('[WS] websocket closed');
    }
  };

  sendMessage = (data: any, callback?: () => void) => {
    // if (callback) {
    // }

    if (this.conn && this.conn.readyState === 1) {
      this.conn.send(JSON.stringify(data));
    } else if (!this.conn || this.conn.readyState === WebSocket.CLOSED) {
      this.conn = null;
      this.initialize();
    }
  };

  ping() {
    if (this.timerKeepConnectServer) {
      clearInterval(this.timerKeepConnectServer);
    }

    this.timerKeepConnectServer = setInterval(() => {
      this.sendMessage({ action: 'ping' });
    }, 1000 * 45);
  }

  private deleteStation(
    { stationId, cameraIds }: { stationId: string; cameraIds: string[] },
    dispatch: any,
  ) {
    console.log('Vao day');

    dispatch(deleteStationLocalInMap(stationId));
    dispatch(closeModal({ modalId: stationId }));
    if (cameraIds.length) {
      dispatch(removeCamerasByListCameraId(cameraIds));
      dispatch(removeWatchListPositionByListCameraId(cameraIds));
    }
  }

  private batteryUpdated(data: any, dispatch: any) {
    const payload: IStationBatteryUpdated = {
      stationId: data.stationId,
      timestamp: data.timestamp,
      newBattery: {
        powerBattery: data.powerBattery,
        powerBatteryCapacityStatus: data.powerBatteryCapacityStatus,
        powerBatteryChargeStatus: data.powerBatteryChargeStatus,
        powerBatteryLastUpdatedAt: data.powerBatteryLastUpdatedAt,
        powerBatteryConnectionStatus: EPowerBatteryStatus.CONNECT,
      },
    };

    dispatch(stationBatteryUpdatedInMap(payload));
  }

  private mobileNetworkUpdate(data: any, dispatch: any) {
    const payload: IStationNetworkUpdated = {
      stationId: data.stationId,
      timestamp: data.timestamp,
      newNetwork: {
        mobileNetwork: data.mobileNetwork,
        mobileNetworkConnectionStatus:
          data.mobileNetworkConnectionStatus || EMobileNetworkStatus.CONNECT,
        mobileNetworkDataUsed: data.mobileNetworkDataUsed,
        mobileNetworkLastUpdatedAt: data.mobileNetworkLastUpdatedAt,
        mobileNetworkOperator: data.mobileNetworkOperator,
        mobileNetworkWaveStrength: data.mobileNetworkWaveStrength,
      },
    };
    dispatch(stationMobileNetworkUpdatedInMap(payload));
  }

  updateStationStatus(data: IStationUpdateStatus, dispatch: any) {
    dispatch(updateStationStatusInMap(data));
  }

  private stationGPSDisconnect(data: any, dispatch: any) {
    const payload = {
      stationId: data.stationId,
      timestamp: data.timestamp,
      gpsStatus: EGpsStatus.DISCONNECT,
    };

    dispatch(stationGpsDisconnectedInMap(payload));
  }

  private stationMobileNetworkDisconnected(data: any, dispatch: any) {
    const payload = { stationId: data.stationId, timestamp: data.timestamp };

    dispatch(stationMobileNetworkDisconnectedInMap(payload));
  }

  private stationBatteryDisconnected(data: any, dispatch: any) {
    const payload = { stationId: data.stationId, timestamp: data.timestamp };
    dispatch(stationBatteryDisconnectedInMap(payload));
  }

  private stationGPSConnected(data: any, dispatch: any) {
    const payload: IUpdateStationGPS = {
      stationId: data.stationId,
      timestamp: data.timestamp,
      newGPS: {
        lat: data.lat,
        lng: data.lng,
        province: data.province,
        district: data.district,
        commune: data.commune,
        address: data.address,
        gpsWaveStrength: data.gpsWaveStrength,
        gpsLastUpdatedAt: data.gpsLastUpdatedAt,
        gpsConnectionStatus: EGpsStatus.CONNECT,
      },
    };

    dispatch(stationGPSConnectedInMap(payload));
  }

  private cameraUpdateStatus(data: any, status: ECameraStatus, dispatch: any) {
    const cameraId = data.cameraId;
    const timestamp = data.timestamp;
    dispatch(updateCameraStatusInMap({ cameraId, timestamp, status }));
    dispatch(changeCameraStatus({ cameraId, status }));
  }

  private cameraDeleted(cameraId: string, dispatch: any) {
    dispatch(deleteCameraInMap(cameraId));
    dispatch(deleteCameraLocal(cameraId));
    dispatch(removeWatchListPositionByListCameraId([cameraId]));
  }

  private reloadPage(dispatch: any, customerIdSocket: string, onlyReload?: boolean) {
    dispatch(changeIsReloadPageEventSocket(true));
    // const { customerId, customers } = store.getState().myAccountSlice;
    // if (customerId === customerIdSocket) dispatch(changeIsReloadPageEventSocket(true));
    // else {
    //   if (!onlyReload) {
    //     dispatch(updateCustomers(customers.filter((customer) => customer.id !== customerIdSocket)));
    //   }
    // }
  }

  private autoLogoutUser(type: TAutoLogout, dispatch: any) {
    dispatch(changeIsAutoLogoutEventSocket({ isAutoLogout: true, type }));
  }

  private orderStationAdded(data: any, dispatch: any) {
    const customerId = data.customerId;
    const orderId = data.orderId;
    const stationIds: string[] = data.stationIds;

    const customerIdStore = store.getState().myAccountSlice.customerId;
    const orderIdStore = store.getState().myAccountSlice.orderId;

    if (customerId === customerIdStore && orderId === orderIdStore) {
      stationIds.map((stationId) => {
        dispatch(getStationGeneral(stationId))
          .unwrap()
          .then((station: IStation) => {
            dispatch(addStationWhenHaveSocketInMap(station));
          });
      });
    }
  }
}

export const ws = new WebSocketClient();
