import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useForm, UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput } from 'src/components/hook_form';
import { ICustomerAddRequest } from 'src/pages/customer_management/customer_add/general_info';
import { validatePreCreate } from 'src/utils/customer';
import { ICustomer } from 'src/types/customer';
import { LoadingButton } from '@mui/lab';
import { useIsRequestPending } from 'src/hooks';

interface IAddCustomerForm {
  handleContinue: (status: boolean) => void;
  addCustomer: (customer: ICustomer, setError?: UseFormSetError<ICustomerAddRequest>) => void;
}

function AddCustomerForm(props: IAddCustomerForm) {
  const { control, setError, handleSubmit } = useForm<ICustomerAddRequest>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      address: '',
      description: '',
    },
  });

  const isCreating = useIsRequestPending('customers', 'createCustomer');
  const { t } = useTranslation();

  const onSubmit = (data: ICustomerAddRequest) => {
    data.entityType = {
      id: 'enterprise',
      name: '',
    };

    const hasError = validatePreCreate(t, data, setError);
    if (hasError) return;

    const customer: ICustomer = {
      ...data,
      entityType: data.entityType.id,
    };

    props.addCustomer(customer, setError);
  };

  const nameLabel = `${t('label.organization.name.main')} *`;
  const emailLabel = `${t('label.email.main')}`;
  const phoneLabel = `${t('label.phoneNumber.main')}`;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography component="h2" variant="h5">
        {t('label.organization.create')}
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Box>
          <FormInput
            control={control}
            label={nameLabel}
            name="name"
            helperText={t('label.organization.name.invalid')}
          />
          <FormInput
            control={control}
            label={emailLabel}
            name="email"
            helperText={t('message.email.validate.require')}
          />
          <FormInput
            control={control}
            label={phoneLabel}
            name="phone"
            helperText={t('message.phoneNumber.validate.require')}
            type="text"
          />
          <FormInput
            control={control}
            label={t('label.address.main')}
            name="address"
            helperText={t('customer.helperText.address')}
          />
        </Box>

        <Box gap={1} display="flex">
          <Button variant="outlined" onClick={() => props.handleContinue(false)}>
            {t('button.back')}
          </Button>
          <LoadingButton variant="contained" type="submit" loading={isCreating}>
            {t('button.continue')}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

export default AddCustomerForm;
