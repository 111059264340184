import axios, { Method } from 'axios';
import config from 'src/config';
import i18n from 'src/i18n';
import store from 'src/redux_store';
import { logoutLocal } from 'src/redux_store/my_account/my_account_slice';
import { toastMessage } from 'src/utils/toast';

type Options = {
  headers?: { [x: string]: string };
  method: Method;
  data?: any;
  params?: any;
  signal?: AbortSignal;
  timeout?: number;
};

export default class ClientBase {
  requestHeaders: { [x: string]: string } = {};
  urlVersion = '/api/v1';
  token = '';
  orderId = '';
  // orderId = '64a4c5d072eadbf3e59bc067';

  constructor() {}

  setToken = (token: string) => {
    if (token) {
      this.token = token;
      this.requestHeaders.Authorization = token;
    } else {
      this.token = '';
      delete this.requestHeaders.Authorization;
    }
  };

  setOrderId = (id: string) => {
    if (id) {
      this.orderId = id;
    } else {
      this.orderId = '';
    }
  };

  getBaseRoute = () => {
    return `${config.baseUrl}${this.urlVersion}`;
  };

  getBaseOrderRoute = () => {
    return `${this.getBaseRoute()}/orders/${this.orderId}`;
  };

  getCameraRoute = () => {
    return `${this.getBaseOrderRoute()}/cameras`;
  };

  getClassifiedListRoute = () => {
    return `${this.getBaseOrderRoute()}/classifiedList`;
  };

  getRoleRoute = () => {
    return `${this.getBaseOrderRoute()}/roles`;
  };

  getUserRoute = () => {
    return `${this.getBaseOrderRoute()}/users`;
  };

  getPermissionRoute = () => {
    return `${this.getBaseRoute()}/permissions`;
  };

  getUserStationRoute = () => {
    return `${this.getBaseRoute()}`;
  };

  getPoliticalRoute = () => {
    return `${this.getBaseRoute()}/political`;
  };

  getStationRoute = () => {
    return `${this.getBaseOrderRoute()}/stations`;
  };

  getPtzRoute = () => {
    return `${this.getBaseOrderRoute()}/cameras`;
  };

  getVideoRoute = () => {
    return `${this.getBaseOrderRoute()}/video`;
  };

  getNotificationRoute = () => {
    return `${this.getBaseOrderRoute()}/notifications`;
  };

  getBlacklistRoute = () => {
    return `${this.getBaseOrderRoute()}/vehicles`;
  };

  getOptions = (options: Options) => {
    const newOptions: Options = { ...options };

    const headers: { [x: string]: string } = { ...this.requestHeaders };

    if (this.token) {
      Object.assign(headers, newOptions.headers);
    }

    return { ...newOptions, headers };
  };

  doFetch = async <T>(url: string, options: Options): Promise<T> => {
    try {
      const response = await axios<T>(url, {
        withCredentials: true,
        ...this.getOptions(options),
      });

      return response.data;
    } catch (error: any) {
      if (error.response?.status && error.response.status === 401) {
        store.dispatch(logoutLocal());
        toastMessage.error(i18n.t('label.system.autoLogout.info'));
      }

      return Promise.reject(error.response?.data ? error.response.data : error.response);
    }
  };
}
