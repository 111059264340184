import { PaymentTransactionType } from 'src/types/payment';
import ClientBase from './base';

export interface ClientPaymentMix {
  getPaymentStatus: (orderId: string) => Promise<{ status: PaymentTransactionType }>;
}

const ClientPayment = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientPaymentMix {
    getPaymentStatus = (orderId: string) => {
      return this.doFetch<{ status: PaymentTransactionType }>(
        `${this.getBaseRoute()}/payment/${orderId}/check-status`,
        {
          method: 'GET',
        },
      );
    };
  };

export default ClientPayment;
