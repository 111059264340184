import { createSlice } from '@reduxjs/toolkit';
import { IPermission, IPermissionScheme } from 'src/types/permission';
import { getPermissions } from './permission_action';
import { removeEmptyscopeInPermissionscheme } from 'src/utils/role';

interface IPermissionSliceState {
  permissionDetail: IPermission;
  permissionList: IPermissionScheme[];
  permissionGroupTabSelect: 'function' | 'user';
}

const initialState: IPermissionSliceState = {
  permissionGroupTabSelect: 'function',
  permissionDetail: { id: '', level: 0, packageId: '', scheme: [] },
  permissionList: [],
};

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    changeTabPermissionGroup: (state, action) => {
      state.permissionGroupTabSelect = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      const data = action.payload;

      state.permissionDetail = data;

      state.permissionList = removeEmptyscopeInPermissionscheme(data.scheme) || [];
    });
  },
});

export const { changeTabPermissionGroup } = permissionSlice.actions;

export default permissionSlice.reducer;
