export const ConfigMapTypes = {
  location: {
    lat: 16.06396,
    lng: 108.213479,
  },
  zoom: 12,
  provinceCode: 48, //Da Nang
  districtCode: 492, //Hai Chau
  communeCode: 20236, //Hai Chau 1
};

export const DANANG_BOUNDS = {
  north: 16.278286,
  south: 15.850562,
  west: 107.754027,
  east: 108.414041,
};

export const ShapeTypes = {
  circle: "circle",
  polygon: "polygon",
  polyline: "polyline",
};

export const MapOptionStyle = [
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit.station",
    stylers: [{ visibility: "off" }],
  },
];

export const TypeClickConst = {
  shape: "shape",
  card: "card",
  marker: "marker",
};
