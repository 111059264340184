import {
  IPaginationStationLogs,
  IStation,
  IStationCard,
  IStationFilter,
  IStationFunction,
  IStationGeneral,
  IStationLog,
  IStationWorkingMode,
} from 'src/types/station';
import ClientBase from './base';
import { ILogFilter, TLevelLog } from 'src/types/log';

export interface ClientStationMix {
  getMyStation: (payload: IStationFilter) => Promise<IStationCard[]>;
  getUserStationConfig: (
    userId: string,
  ) => Promise<{ trackAll: boolean; stationList: IStationCard[] }>;

  getStations: (filter: IStationFilter) => Promise<IStation[]>;
  getStationManage: (filter?: IStationFilter) => Promise<IStation[]>;
  getStationBaseInfo: (stationId: string) => Promise<IStation>;
  getStationGeneral: (stationId: string) => Promise<IStation>;
  getStationFunction: (stationId: string) => Promise<IStationFunction>;
  getStationLogs: (
    stationId: string,
    params: ILogFilter,
  ) => Promise<IPaginationStationLogs<IStationLog>>;

  activeStation: (code: string) => Promise<IStation>;
  createStation: (data: Omit<IStationGeneral, 'id'>) => Promise<IStation>;
  createStationLight: (
    stationId: string,
    name: string,
  ) => Promise<{
    stationId: string;
    lightId: string;
    lightName: string;
  }>;
  updateStationGeneral: (stationId: string, data: Omit<IStationGeneral, 'id'>) => Promise<IStation>;
  updateStationFunction: (
    stationId: string,
    data: {
      functionEnabled: boolean;
    },
  ) => Promise<IStation>;
  updateStationLight: (
    stationId: string,
    lightId: string,
    name: string,
  ) => Promise<{
    stationId: string;
    lightId: string;
    lightName: string;
  }>;
  updateWorkingMode: (stationId: string, data: IStationWorkingMode) => Promise<string>;
  deleteStation: (stationId: string) => Promise<string>;
  deleteStationLight: (stationId: string, lightId: string) => Promise<string>;

  toggleLightStatus: (data: { stationId: string }) => Promise<IStation>;
}

const ClientStation = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientStationMix {
    getMyStation = async (payload: IStationFilter) => {
      return this.doFetch<IStationCard[]>(`${this.getUserStationRoute()}/user_station/me/station`, {
        method: 'post',
        data: payload,
      });
    };

    getUserStationConfig = async (userId: string) => {
      return this.doFetch<{ trackAll: boolean; stationList: IStationCard[] }>(
        `${this.getUserStationRoute()}/user_station/${userId}/stations/config`,
        {
          method: 'get',
        },
      );
    };

    activeStation = async (code: string) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}/code`, {
        method: 'POST',
        data: { code },
      });
    };

    createStation = async (data: Omit<IStationGeneral, 'id'>) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}`, {
        method: 'POST',
        data,
      });
    };

    createStationLight = async (stationId: string, name: string) => {
      return this.doFetch<{ stationId: string; lightId: string; lightName: string }>(
        `${this.getStationRoute()}/${stationId}/light`,
        {
          method: 'POST',
          data: { name },
        },
      );
    };

    getStations = async (params: IStationFilter) => {
      return this.doFetch<IStation[]>(`${this.getStationRoute()}`, {
        method: 'GET',
        params,
      });
    };

    getStationGeneral = async (stationId: string) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}/${stationId}/general`, {
        method: 'GET',
      });
    };

    getStationFunction = async (stationId: string) => {
      return this.doFetch<IStationFunction>(`${this.getStationRoute()}/${stationId}/function`, {
        method: 'GET',
      });
    };

    getStationManage = async (params?: IStationFilter) => {
      return this.doFetch<IStation[]>(`${this.getStationRoute()}`, {
        method: 'GET',
        params,
      });
    };

    getStationBaseInfo = async (stationId: string) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}/${stationId}/base_info`, {
        method: 'GET',
      });
    };

    getStationLogs = async (stationId: string, params: ILogFilter) => {
      return this.doFetch<IPaginationStationLogs<IStationLog>>(
        `${this.getStationRoute()}/${stationId}/logs`,
        {
          method: 'GET',
          params,
        },
      );
    };

    updateStationGeneral = async (stationId: string, data: Omit<IStationGeneral, 'id'>) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}/${stationId}/general`, {
        method: 'PUT',
        data,
      });
    };

    updateStationFunction = async (
      stationId: string,
      data: {
        functionEnabled: boolean;
      },
    ) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}/${stationId}/function`, {
        method: 'PUT',
        data,
      });
    };

    updateStationLight = async (stationId: string, lightId: string, name: string) => {
      return this.doFetch<{ stationId: string; lightId: string; lightName: string }>(
        `${this.getStationRoute()}/${stationId}/light/${lightId}`,
        {
          method: 'PUT',
          data: {
            name,
          },
        },
      );
    };

    updateWorkingMode = async (stationId: string, data: IStationWorkingMode) => {
      return this.doFetch<string>(`${this.getStationRoute()}/${stationId}/working_mode`, {
        method: 'PUT',
        data,
      });
    };

    deleteStation = async (stationId: string) => {
      return this.doFetch<string>(`${this.getStationRoute()}/${stationId}`, {
        method: 'DELETE',
      });
    };

    deleteStationLight = async (stationId: string, lightId: string) => {
      return this.doFetch<string>(`${this.getStationRoute()}/${stationId}/light/${lightId}`, {
        method: 'DELETE',
      });
    };

    toggleLightStatus = async ({ stationId }: { stationId: string }) => {
      return this.doFetch<IStation>(`${this.getStationRoute()}/${stationId}/light/toggle`, {
        method: 'PUT',
      });
    };
  };

export default ClientStation;
