import { Box, Button, Drawer, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import theme from 'src/theme';
import { v4 as uuidv4 } from 'uuid';
import logo from '../../../assets/images/short-logo.png';

const EncourageUseApplication = ({
  open,
  handleOpenDrawer,
}: {
  open: boolean;
  handleOpenDrawer: (status: boolean) => void;
}) => {
  const { t } = useTranslation();
  const appScheme = 'danapod://';
  const appStoreURL = 'https://apps.apple.com/us/app/danapod/id6466560042';

  const openAppInAndroid = async () => {
    window.location.replace('market://launch?id=com.danateq.danapod');
    handleOpenDrawer(false);
  };

  function isSafari() {
    const userAgent = window.navigator.userAgent;
    return /Safari/i.test(userAgent) && !/Chrome|CriOS|FxiOS|Edg|OPiOS/i.test(userAgent);
  }

  const openAppInIos = function () {
    if (isSafari()) {
      window.location.href = appStoreURL;
    } else {
      window.location.href = appScheme;

      setTimeout(() => {
        if (document.hidden) {
          window.location.reload();
        } else {
          window.location.href = appStoreURL;
        }
      }, 1000);
    }

    handleOpenDrawer(false);
  };

  const handleMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      openAppInAndroid();
      return;
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      openAppInIos();
      return;
    }

    return handleOpenDrawer(false);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    handleOpenDrawer(open);
  };

  // useEffect(() => {
  //   document.addEventListener('visibilitychange', function () {
  //     if (document.visibilityState === 'visible') {
  //       location.reload();
  //     }
  //   });
  //   // window.onbeforeunload = function () {
  //   //   return null;
  //   // };
  // }, []);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        '& .css-9emuhu-MuiPaper-root-MuiDrawer-paper,.css-fy2uxz': {
          overflow: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      }}
    >
      <Box
        sx={{
          borderRadius: '50%',
          background: theme.palette.background.paper,
          display: 'grid',
          placeItems: 'center',
          transform: 'translateY(-70px)',
          padding: '20px',
        }}
      >
        <LazyLoadImage src={logo} alt="logo" width={'110px'} />
      </Box>
      <Box
        display={'flex'}
        flex={1}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={3}
        padding={'0 25px'}
        sx={{
          transform: 'translateY(-50px)',
        }}
      >
        <Typography fontSize={'38px'} textAlign={'center'}>
          {t('label.openApp')}
        </Typography>
        <Box
          gap={1.5}
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            '& button': {
              width: '80%',
              padding: '16px',
            },
          }}
        >
          <Button variant="contained" onClick={handleMobileOS}>
            <Typography fontSize={'28px'} textAlign={'center'}>
              {t('button.openApp')}
            </Typography>
          </Button>
          <Button
            variant="text"
            onClick={() => {
              sessionStorage.setItem('download-app-dismiss', uuidv4());
              handleOpenDrawer(false);
            }}
          >
            <Typography fontSize={'28px'} textAlign={'center'}>
              {t('button.notNow')}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default EncourageUseApplication;
