import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { ICustomer, TEntityType } from 'src/types/customer';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { createCustomer } from 'src/redux_store/customer/customers/actions';
import { toastMessage } from 'src/utils/toast';
import { validatePreCreate } from 'src/utils/customer';
import { useIsRequestPending } from 'src/hooks';
import { getMyOrderRoles, getMyOrders } from 'src/redux_store/my_account/my_account_action';
import CustomerInfoForm from 'src/components/customer_info_form';

interface IGeneralInfoProps {
  handleClose: () => void;
  modalId: string;
}

export interface ICustomerAddRequest {
  name: string;
  email: string;
  phone: string;
  address: string;
  description: string;
  entityType: { id: TEntityType; name: string } | null;
}

function GeneralInfo(props: IGeneralInfoProps) {
  const { modalId, handleClose } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { productPackages } = useAppSelector(({ productPackageSlice }) => productPackageSlice);

  const isCreating = useIsRequestPending('customers', 'createCustomer');

  const { control, setError, handleSubmit } = useForm<ICustomerAddRequest>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      address: '',
      description: '',
      entityType: null,
    },
  });

  const onSubmit = (data: ICustomerAddRequest) => {
    const hasError = validatePreCreate(t, data, setError);

    if (hasError || isEmpty(data.entityType)) return;

    const customer: ICustomer = {
      ...data,
      entityType: data.entityType.id,
    };

    console.log({ productPackages });

    const order = {
      packageId: productPackages[0].id,
      expiresAt: moment().add(3, 'months').format(),
    };

    dispatch(createCustomer({ order, customer }))
      .unwrap()
      .then((newCustomer) => {
        // toastMessage.success(t('message.customer.add.success'));
        dispatch(closeModal({ modalId }));
        navigate(`/customers/${newCustomer.id}/stations`);

        if (!newCustomer.id) return;

        localStorage.setItem('customer_order', newCustomer.id);

        dispatch(getMyOrders(newCustomer.id))
          .unwrap()
          .then((data) => {
            if (data.length) {
              localStorage.setItem('order', data[0].order.id);
              dispatch(getMyOrderRoles(data[0].order.id));
            }
          });
      })
      .catch((error) => {
        toastMessage.setErrors(error, setError);
      });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      px={2}
      pb={2}
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
    >
      <Box flex={1}>
        <CustomerInfoForm control={control} />
      </Box>
      <Box pb={1} gap={1} display="flex" justifyContent="flex-end">
        <Button variant="outlined" onClick={handleClose}>
          {t('button.cancel')}
        </Button>
        <LoadingButton loading={isCreating} type="submit" variant="contained">
          {t('button.add.new')}
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default GeneralInfo;
