import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICreateCustomerRequest,
  ICustomer,
  ICustomerData,
  ICustomerFilter,
  ICustomerPatch,
} from 'src/types/customer';

import client from 'src/clients/http';
import { toastMessage } from 'src/utils/toast';

export const createCustomer = createAsyncThunk<ICustomer, ICreateCustomerRequest>(
  'customers/createCustomer',
  async (payload, { rejectWithValue }) => {
    try {
      const customer = await client.createCustomer(payload);
      return customer;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getCustomers = createAsyncThunk<ICustomer[], { name: '' }>(
  'customers/getAll',
  async (payload, { rejectWithValue }) => {
    try {
      const customers = await client.getCustomers(payload);
      return customers;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getSingleCustomer = createAsyncThunk<ICustomer, string>(
  `customers/getSingleCustomer`,
  async (id, { rejectWithValue }) => {
    try {
      const customer = await client.getSingleCustomer(id);
      return customer;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const filterCustomer = createAsyncThunk<ICustomerData, ICustomerFilter>(
  'customers/filter',
  async (payload, { rejectWithValue }) => {
    try {
      const customers = await client.filterCustomer(payload);
      return customers;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const updateCustomer = createAsyncThunk<
  { id: string; data: ICustomerPatch },
  { id: string; data: ICustomerPatch }
>('customers/updateCustomer', async (payload, { rejectWithValue }) => {
  try {
    await client.updateCustomer(payload.id, payload.data);
    return payload;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const deleteCustomer = createAsyncThunk<string, string>(
  'customers/deleteCustomer',
  async (id, { rejectWithValue }) => {
    try {
      await client.deleteCustomer(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
