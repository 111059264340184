import { TFunction } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { isValidEmail, isValidPhoneNumber } from './common';
import {
  ICustomer,
  ICustomerPatch,
  IOrderMemberStatus,
  TOrderMemberStatus,
} from 'src/types/customer';
import { ICustomerAddRequest } from 'src/pages/customer_management/customer_add/general_info';
import theme from 'src/theme';
import { OrderMemberStatusOptions } from 'src/constants/customer';
import { TI18n } from 'src/types/common';

export const validatePreCreate = (
  t: TFunction<'translation', undefined>,
  customer: ICustomerAddRequest,
  setError: UseFormSetError<any>,
) => {
  let hasError = false;

  if (!customer.name || !customer.name.trim()) {
    setError('name', { message: t('message.organization.name.validate.invalid') });
    hasError = true;
  }

  if (customer.email && !isValidEmail(customer.email)) {
    setError('email', { message: t('label.email.helperText.invalid') });
    hasError = true;
  }

  if (customer.phone && !isValidPhoneNumber(customer.phone)) {
    setError('phone', { message: t('label.phoneNumber.helperText.invalid') });
    hasError = true;
  }

  if (isEmpty(customer.entityType)) {
    setError('entityType', { message: t('label.type.helperText.invalid') });
    hasError = true;
  }

  return hasError;
};

export const validatePreSave = (
  t: TFunction<'translation', undefined>,
  customer: ICustomerPatch,
  setError: UseFormSetError<any>,
) => {
  let hasError = false;

  if (customer.name !== undefined && !customer.name?.trim()) {
    setError('name', { message: t('message.customer.name.validate.invalid') });
    hasError = true;
  }

  if (customer.email && !isValidEmail(customer.email)) {
    setError('email', { message: t('label.email.helperText.invalid') });
    hasError = true;
  }

  if (customer.phone && !isValidPhoneNumber(customer.phone)) {
    setError('phone', { message: t('label.phoneNumber.helperText.invalid') });
    hasError = true;
  }

  if (customer.entityType !== undefined && isEmpty(customer.entityType)) {
    setError('entityType', { message: 'label.type.helperText.invalid' });
    hasError = true;
  }

  return hasError;
};

export const renderAvatarColor = (index: number) => {
  const colors = {
    primary: theme.palette.primary.main,
    error: theme.palette.error.main,
    success: theme.palette.success.main,
    warning: theme.palette.warning.main,
  };
  if (index % 4 === 0) return colors.primary;
  if (index % 4 === 1) return colors.error;
  if (index % 4 === 2) return colors.success;
  if (index % 4 === 3) return colors.warning;
};

export const renderOrderMemberStatus = (t: TI18n, status?: TOrderMemberStatus) => {
  const result = OrderMemberStatusOptions(t).find((item) => item.id === status);

  if (!result)
    return {
      name: 'unknown',
      color: 'default',
    } as IOrderMemberStatus;
  return result;
};

export const equalMemberStatus = (equalStatus: TOrderMemberStatus, status?: TOrderMemberStatus) => {
  return equalStatus === status;
};

export const getLabel = (
  customerId: string,
  customers: ICustomer[],
  t: TFunction<'translation', undefined>,
) => {
  let label = t('label.customer');
  const customer = customers.find((item) => item.id === customerId);

  if (customer?.entityType === 'individual') label = t('label.personal');
  if (customer?.entityType === 'enterprise') label = t('label.organization.main');

  return label;
};
