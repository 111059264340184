export const PATH = {
  cameraMap: '/cameras',
  // stationDetail: '/stations/:id',

  login: '/login',
  register: '/register',
  verifyPassword: '/change_password',
  cameraManagement: '/camera_management',
  userManagement: '/user_management',
  userDetailManagement: '/user_management/:memberId',
  stationManagement: '/station_management',
  roleManagement: '/role_management',
  roleDetail: '/role_management/:roleId',
  aiEvent: '/ai_event',
  systemManagement: '/system_management',
  notification: '/notification',
  eventDetail: '/event_detail',
  blacklistManagement: '/blacklist_management',
  customerManagement: '/customer_management',
  // customerDetailOld: '/customer_management1/:customerId',
  customerOrder: '/customer_management/:customerId/orders/:orderId',
  notFound: '*',

  addCustomer: '/customers/create',
  stationMap: '/customers/:customerId/stations',
  watchList: '/customers/:customerId/watch_list',
  customerDetail: '/customers/:customerId/manage',
  stationDetail: '/customers/:customerId/stations/:id',
  customerMembers: '/customers/:customerId/members',
};
