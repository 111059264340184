import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ConfigMapTypes } from 'src/constants/map';
import { ICamera } from 'src/types/camera';
import { ILocation } from 'src/types/common';
import { EStationAction, EWorkingMode, IStation, WorkingSchedule } from 'src/types/station';
import {
  createCameraStation,
  deleteCamera,
  deleteCameraStation,
  updateCameraStationGeneral,
} from '../camera/camera_action';
import {
  createStation,
  createStationLight,
  deleteStation,
  deleteStationLight,
  getStationFunction,
  getStations,
  updateStationFunction,
  updateStationGeneral,
  updateStationLight,
  updateWorkingMode,
} from './station_action';

interface IState {
  stations: IStation[];
  selectedStation: IStation | null;
  stationAction: EStationAction;
  isEditingLocation: boolean;
  isConfigStation: boolean;
  createLocation: ILocation | null;
  updateLocation: ILocation | null;
  functions: {
    stationId: string;
    cameras: ICamera[];
    lights: { id: string; name: string }[];
    workingMode: EWorkingMode;
    workingSchedule: WorkingSchedule;

    micStatus: boolean;
    isMicAutoClick: boolean;
    speakerStatus: boolean;
    isSpeakerAutoClick: boolean;
  };
}

const initialState: IState = {
  stations: [],
  selectedStation: null,
  stationAction: EStationAction.CLOSE,
  isEditingLocation: false,
  isConfigStation: false,
  createLocation: ConfigMapTypes.location,
  updateLocation: null,
  functions: {
    stationId: '',
    cameras: [],
    lights: [],
    workingMode: EWorkingMode.FULLTIME,
    workingSchedule: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    micStatus: false,
    speakerStatus: false,
    isMicAutoClick: false,
    isSpeakerAutoClick: false,
  },
};

const StationSlice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    setStationAction: (state: IState, action: PayloadAction<EStationAction>) => {
      state.stationAction = action.payload;
    },

    setSelectedStation: (state: IState, action: PayloadAction<IStation | null>) => {
      state.selectedStation = action.payload;
    },

    toggleStationMic: (state: IState, action: PayloadAction<{ autoClick: boolean }>) => {
      state.functions.micStatus = !state.functions.micStatus;
      state.functions.isMicAutoClick = action.payload.autoClick;
    },

    toggleStationSpeaker: (state: IState, action: PayloadAction<{ autoClick: boolean }>) => {
      state.functions.speakerStatus = !state.functions.speakerStatus;
      state.functions.isSpeakerAutoClick = action.payload.autoClick;
    },

    setIsEditingStationLocation: (state: IState, action: PayloadAction<boolean>) => {
      state.isEditingLocation = action.payload;
    },

    setIsConfigStation: (state: IState, action: PayloadAction<boolean>) => {
      state.isConfigStation = action.payload;
    },

    setCreateStationLocation: (state: IState, action: PayloadAction<ILocation>) => {
      state.createLocation = action.payload;
    },

    setUpdateStationLocation: (state: IState, action: PayloadAction<ILocation | null>) => {
      state.updateLocation = action.payload;
    },

    resetStationState: (state: IState) => {
      state.stations = initialState.stations;
      state.selectedStation = initialState.selectedStation;
      state.stationAction = initialState.stationAction;
      state.isEditingLocation = initialState.isEditingLocation;
      state.createLocation = initialState.createLocation;
      state.updateLocation = initialState.updateLocation;
    },

    addStationWhenHaveSocket: (state: IState, action: PayloadAction<IStation>) => {
      const station = action.payload;
      const newData = [station, ...state.stations];

      state.stations = newData;
    },

    resetStationFunctions: (state: IState) => {
      state.functions.isMicAutoClick = initialState.functions.isMicAutoClick;
      state.functions.isSpeakerAutoClick = initialState.functions.isSpeakerAutoClick;
      state.functions.micStatus = initialState.functions.micStatus;
      state.functions.speakerStatus = initialState.functions.speakerStatus;
    },
  },
  extraReducers(builder) {
    builder.addCase(getStations.fulfilled, (state, action) => {
      state.stations = action.payload;
    });

    builder.addCase(createStation.fulfilled, (state, action) => {
      state.stations = [...state.stations, action.payload];
    });

    builder.addCase(updateStationGeneral.fulfilled, (state, action) => {
      const stations = [...state.stations];
      const index = stations.findIndex((station) => station.id === action.meta.arg.stationId);

      if (index !== -1) {
        const stations = [...state.stations];

        const newStation = action.payload;
        stations.splice(index, 1, newStation);

        state.stations = stations;
      }
    });

    builder.addCase(deleteStation.fulfilled, (state, action) => {
      const stations = state.stations.filter((station) => station.id !== action.payload);

      state.stations = stations;
    });

    builder.addCase(getStationFunction.pending, (state) => {
      state.functions = initialState.functions;
    });

    builder.addCase(getStationFunction.fulfilled, (state, action) => {
      state.functions.cameras = action.payload?.cameras || [];
      state.functions.lights = action.payload?.lights || [];
      state.functions.stationId = action.meta.arg;
      state.functions.workingMode = action.payload.workingMode;
      state.functions.workingSchedule = action.payload.workingSchedule;
    });

    builder.addCase(updateWorkingMode.fulfilled, (state, action) => {
      const { data, stationId } = action.payload;

      if (state.functions.stationId === stationId) {
        state.functions.workingMode = data.mode;
        if (data.mode === EWorkingMode.SCHEDULED) {
          state.functions.workingSchedule = data.schedule;
        }
      }
    });

    builder.addCase(createStationLight.fulfilled, (state, action) => {
      state.functions.lights = [
        ...state.functions.lights,
        { id: action.payload.lightId, name: action.payload.lightName },
      ];
    });

    builder.addCase(updateStationLight.fulfilled, (state, action) => {
      const lights = [...state.functions.lights];

      const index = lights.findIndex((item) => item.id === action.payload.lightId);
      lights.splice(index, 1, { id: action.payload.lightId, name: action.payload.lightName });

      state.functions.lights = lights;
    });

    builder.addCase(deleteStationLight.fulfilled, (state, action) => {
      const lights = state.functions.lights.filter((l) => l.id !== action.payload.lightId);
      state.functions.lights = lights;
    });

    builder.addCase(createCameraStation.fulfilled, (state, action) => {
      if (state.functions.stationId === action.meta.arg.stationId) {
        state.functions.cameras = [...state.functions.cameras, action.payload];
      }
    });

    builder.addCase(deleteCamera.fulfilled, (state, action) => {
      const cameras = state.functions.cameras.filter((cam) => cam.id !== action.payload);

      state.functions.cameras = cameras;
    });

    builder.addCase(deleteCameraStation.fulfilled, (state, action) => {
      const cameras = state.functions.cameras.filter((cam) => cam.id !== action.payload.cameraId);

      state.functions.cameras = cameras;
    });

    builder.addCase(updateCameraStationGeneral.fulfilled, (state, action) => {
      const index = state.functions.cameras.findIndex((cam) => cam.id === action.payload.cameraId);

      if (index !== -1) {
        const cameras = [...state.functions.cameras];

        cameras.splice(index, 1, {
          ...cameras[index],
          address: action.payload.data.address,
          name: action.payload.data.name,
          lat: action.payload.data.lat,
          lng: action.payload.data.lng,
        });

        state.functions.cameras = cameras;
      }
    });
    builder.addCase(updateStationFunction.fulfilled, (state, action) => {
      const index = state.stations.findIndex((station) => station.id === action.meta.arg.stationId);

      if (index !== -1) {
        const stations = [...state.stations];

        const newStation = action.payload;
        stations.splice(index, 1, newStation);

        state.stations = stations;
      }
    });
  },
});

export const {
  resetStationState,
  setStationAction,
  setSelectedStation,
  setCreateStationLocation,
  setIsEditingStationLocation,
  setUpdateStationLocation,
  toggleStationSpeaker,
  toggleStationMic,
  resetStationFunctions,
  setIsConfigStation,
  addStationWhenHaveSocket,
} = StationSlice.actions;

export default StationSlice.reducer;
