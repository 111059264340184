import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormAutocomplete, FormInput } from 'src/components/hook_form';
import { EntityTypeOptions } from 'src/constants/customer';

interface ICustomerInfoProps {
  control: any;
}

function CustomerInfoForm(props: ICustomerInfoProps) {
  const { control } = props;
  const { t } = useTranslation();

  // const nameLabel = `${t('customer.name')} *`;
  const nameLabel = `${t('label.name.personalOrOrganization')} *`;
  const emailLabel = `${t('label.email.main')}`;
  const phoneLabel = `${t('label.phoneNumber.main')}`;

  return (
    <React.Fragment>
      <FormInput
        control={control}
        label={nameLabel}
        name="name"
        helperText={t('message.organization.name.validate.invalid')}
      />
      <FormInput
        control={control}
        label={emailLabel}
        name="email"
        // helperText={t('label.email.helperText.invalid')}
      />
      <FormInput
        control={control}
        label={phoneLabel}
        name="phone"
        // helperText={t('label.phoneNumber.helperText.invalid')}
        type="text"
      />
      <FormAutocomplete
        control={control}
        label={`${t('label.type.main')} *`}
        name="entityType"
        options={EntityTypeOptions(t)}
        keyOption="id"
        labelOption="name"
        disableClearable
      />
      <FormInput
        control={control}
        label={t('label.address.main')}
        name="address"
        // helperText={t('customer.helperText.address')}
      />
      <FormInput control={control} label={t('label.description.main')} name="description" />
    </React.Fragment>
  );
}

export default CustomerInfoForm;
