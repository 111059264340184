import React from 'react';
import { Box, DialogTitle } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';

import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import { useAppDispatch } from 'src/redux_store';

import DialogWrapper from 'src/components/modal/dialog_wrapper';
import GeneralInfo from './general_info';
import { useTranslation } from 'react-i18next';

function AddCustomerModal({ modalId }: { modalId: string }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(closeModal({ modalId }));
  };

  return (
    <DialogWrapper
      modalId={modalId}
      maxWidth={600}
      minWidth={600}
      sx={{ minHeight: 620, height: 620 }}
      // isFullHeight
      prevClose={handleClose}
    >
      <Box mt={1} display="flex" flexDirection="column" alignItems="center" height="100%">
        <Box>
          <DialogTitle sx={{ py: 1 }}>{t('label.general')}</DialogTitle>
        </Box>

        <Scrollbars>
          <GeneralInfo handleClose={handleClose} modalId={modalId} />
        </Scrollbars>
      </Box>
    </DialogWrapper>
  );
}

export default AddCustomerModal;
