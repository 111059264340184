import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import client from 'src/clients/http';

import { useAppDispatch, useAppSelector } from 'src/redux_store';
import {
  getMe,
  getMyCustomers,
  getMyOrderRoles,
  getMyOrders,
} from 'src/redux_store/my_account/my_account_action';
import { changeUserSession } from 'src/redux_store/my_account/my_account_slice';
import { PATH } from 'src/routes/path';

const Authentication = () => {
  const dispatch = useAppDispatch();
  const token = Cookies.get('USER_SESSION');
  const { isReload, userSession } = useAppSelector((state) => state.myAccountSlice);

  const navigate = useNavigate();

  // const [authToken, setAuthToken] = useState(Cookies.get('USER_SESSION'));
  let intervalCookie: any;

  const checkCookie = () => {
    const currentToken = Cookies.get('USER_SESSION');

    if (currentToken !== userSession && isReload) {
      dispatch(changeUserSession(currentToken));
      window.location.reload();
      clearInterval(intervalCookie);
    }
  };

  useEffect(() => {
    intervalCookie = setInterval(checkCookie, 5000);
    return () => clearInterval(intervalCookie);
  }, [isReload]);

  useEffect(() => {
    if (token) {
      const getAuth = async () => {
        client.setToken(token);

        const [user, customers] = await Promise.all([
          dispatch(getMe()).unwrap(),
          dispatch(getMyCustomers()).unwrap(),
        ]);

        if (!customers.length) {
          navigate(PATH.addCustomer);
          return;
        }

        if (customers.length && customers[0].id && user) {
          let customerId = customers[0].id;
          const local = localStorage.getItem('customer_order');
          if (local && customers.findIndex((customer) => customer.id === local) !== -1) {
            customerId = local;
          } else {
            localStorage.removeItem('customer_order');
          }

          const orders = await dispatch(getMyOrders(customerId)).unwrap();

          if (orders.length) {
            let orderId = orders[0].order.id;
            const local = localStorage.getItem('order');
            if (local && orders.findIndex(({ order }) => order.id === local) !== -1) {
              orderId = local;
            } else {
              localStorage.removeItem('order');
            }
            dispatch(getMyOrderRoles(orderId));
          }
        }
      };

      getAuth();
    }
  }, [token, dispatch]);

  return <React.Fragment />;
};

export default Authentication;
