import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';

import { RootState } from 'src/redux_store';
import {
  ICamera,
  ICameraConfig,
  ICameraConfigPatch,
  ICameraFilter,
  ICameraFunction,
  TCameraGeneral,
} from 'src/types/camera';
import { IRequestError } from 'src/types/error';
import { INotification } from 'src/types/notification';

export const createCamera = createAsyncThunk<
  ICamera,
  TCameraGeneral,
  { state: RootState; rejectValue: IRequestError }
>('camera/createCamera', async (payload, { rejectWithValue }) => {
  try {
    const address = payload.addressFull;
    delete payload.addressFull;

    const data = await client.createCamera(payload);
    data.address = address || '';
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const createCameraStation = createAsyncThunk<
  ICamera,
  { stationId: string; data: TCameraGeneral },
  { state: RootState; rejectValue: IRequestError }
>('camera/createCameraStation', async (payload, { rejectWithValue }) => {
  try {
    const address = payload.data.addressFull;
    delete payload.data.addressFull;
    const data = await client.createCameraStation(payload.stationId, payload.data);
    data.address = address || '';
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getCameras = createAsyncThunk<
  ICamera[],
  ICameraFilter,
  { state: RootState; rejectValue: IRequestError }
>('camera/getCameras', async (filter, { rejectWithValue }) => {
  try {
    const data = await client.getCameras(filter);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getCamerasOfConfigs = createAsyncThunk<
  ICamera[],
  ICameraFilter,
  { state: RootState; rejectValue: IRequestError }
>('camera/getCamerasOfConfigs', async (filter, { rejectWithValue }) => {
  try {
    const data = await client.getCamerasOfConfigs(filter);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getCamerasByStationId = createAsyncThunk<
  ICamera[],
  string,
  { state: RootState; rejectValue: IRequestError }
>('camera/getCamerasByStationId', async (stationId, { rejectWithValue }) => {
  try {
    const data = await client.getCamerasByStationId(stationId);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getCameraGeneral = createAsyncThunk<
  TCameraGeneral,
  string,
  { state: RootState; rejectValue: IRequestError }
>('camera/getCameraGeneral', async (cameraId, { rejectWithValue }) => {
  try {
    const data = await client.getCameraGeneral(cameraId);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getCameraConfig = createAsyncThunk<
  ICameraConfig,
  string,
  { state: RootState; rejectValue: IRequestError }
>('camera/getCameraConfig', async (cameraId, { rejectWithValue }) => {
  try {
    const data = await client.getCameraConfig(cameraId);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getCameraFunction = createAsyncThunk<
  ICameraFunction,
  string,
  { state: RootState; rejectValue: IRequestError }
>('camera/getCameraFunction', async (cameraId, { rejectWithValue }) => {
  try {
    const data = await client.getCameraFunction(cameraId);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getCameraStationGeneral = createAsyncThunk<
  TCameraGeneral,
  { cameraId: string; stationId: string },
  { state: RootState; rejectValue: IRequestError }
>('camera/getCameraStationGeneral', async ({ cameraId, stationId }, { rejectWithValue }) => {
  try {
    const data = await client.getCameraStationGeneral(cameraId, stationId);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getCameraStationConfig = createAsyncThunk<
  ICameraConfig,
  { cameraId: string; stationId: string },
  { state: RootState; rejectValue: IRequestError }
>('camera/getCameraStationConfig', async ({ cameraId, stationId }, { rejectWithValue }) => {
  try {
    const data = await client.getCameraStationConfig(cameraId, stationId);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getCameraStationFunction = createAsyncThunk<
  ICameraFunction,
  { cameraId: string; stationId: string },
  { state: RootState; rejectValue: IRequestError }
>('camera/getCameraStationFunction', async ({ cameraId, stationId }, { rejectWithValue }) => {
  try {
    const data = await client.getCameraStationFunction(cameraId, stationId);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getCameraLogs = createAsyncThunk<
  INotification[],
  {
    cameraId: string;
    params: {
      after: string;
      limit: number;
    };
  },
  { state: RootState; rejectValue: IRequestError }
>('camera/getCameraLogs', async ({ cameraId, params }, { rejectWithValue }) => {
  try {
    const data = await client.getCameraLogs(cameraId, params);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateCameraGeneral = createAsyncThunk<
  { cameraId: string; data: TCameraGeneral },
  { cameraId: string; data: TCameraGeneral },
  { state: RootState; rejectValue: IRequestError }
>('camera/updateCameraGeneral', async ({ cameraId, data }, { rejectWithValue }) => {
  try {
    const address = data.addressFull;
    delete data.addressFull;
    await client.updateCameraGeneral(cameraId, data);

    data.address = address || '';
    return { data, cameraId };
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateCameraStationGeneral = createAsyncThunk<
  { cameraId: string; data: TCameraGeneral },
  { cameraId: string; stationId: string; data: TCameraGeneral },
  { state: RootState; rejectValue: IRequestError }
>(
  'camera/updateCameraStationGeneral',
  async ({ cameraId, stationId, data }, { rejectWithValue }) => {
    try {
      const address = data.addressFull;
      delete data.addressFull;
      await client.updateCameraStationGeneral(cameraId, stationId, data);

      data.address = address || '';
      return { data, cameraId };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateCameraConfig = createAsyncThunk<
  string,
  { cameraId: string; data: ICameraConfigPatch },
  { state: RootState; rejectValue: IRequestError }
>('camera/updateCameraConfig', async ({ cameraId, data }, { rejectWithValue }) => {
  try {
    await client.updateCameraConfig(cameraId, data);
    return cameraId;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateCameraStationConfig = createAsyncThunk<
  string,
  { cameraId: string; stationId: string; data: ICameraConfigPatch },
  { state: RootState; rejectValue: IRequestError }
>(
  'camera/updateCameraStationConfig',
  async ({ cameraId, stationId, data }, { rejectWithValue }) => {
    try {
      await client.updateCameraStationConfig(cameraId, stationId, data);
      return cameraId;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateCameraFunction = createAsyncThunk<
  { cameraId: string; functionEnabled: boolean },
  { cameraId: string; data: Partial<ICameraFunction> },
  { state: RootState; rejectValue: IRequestError }
>('camera/updateCameraFunction', async ({ cameraId, data }, { rejectWithValue }) => {
  try {
    await client.updateCameraFunction(cameraId, data);
    return { cameraId, functionEnabled: Boolean(data.functionEnabled) };
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateCameraStationFunction = createAsyncThunk<
  { cameraId: string; stationId: string; functionEnabled: boolean },
  { cameraId: string; stationId: string; data: Partial<ICameraFunction> },
  { state: RootState; rejectValue: IRequestError }
>(
  'camera/updateCameraStationFunction',
  async ({ cameraId, stationId, data }, { rejectWithValue }) => {
    try {
      await client.updateCameraStationFunction(cameraId, stationId, data);
      return { cameraId, stationId, functionEnabled: Boolean(data.functionEnabled) };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteCamera = createAsyncThunk<
  string,
  string,
  { state: RootState; rejectValue: IRequestError }
>('camera/deleteCamera', async (cameraId, { rejectWithValue }) => {
  try {
    await client.deleteCamera(cameraId);
    return cameraId;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const deleteCameraStation = createAsyncThunk<
  { stationId: string; cameraId: string },
  { stationId: string; cameraId: string },
  { state: RootState; rejectValue: IRequestError }
>('camera/deleteCameraStation', async (payload, { rejectWithValue }) => {
  try {
    await client.deleteCameraStation(payload.cameraId, payload.stationId);
    return payload;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const refreshSnapshot = createAsyncThunk<
  string,
  string,
  { state: RootState; rejectValue: IRequestError }
>('camera/refreshSnapshot', async (cameraId, { rejectWithValue }) => {
  try {
    await client.refreshSnapshot(cameraId);
    return cameraId;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
