import { ExpandLessOutlined, ExpandMore, Menu } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation, useNavigate } from 'react-router-dom';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { useTranslation } from 'react-i18next';
import { ROLE_LEVEL } from 'src/constants/role';
import { useAppSelector } from 'src/redux_store';
import { MENU_LIST } from 'src/routes/menu';
import { PATH } from 'src/routes/path';
import { EPermissions } from 'src/types/enum';
import { IMenuRoute } from 'src/types/route';
import { ListSidebar, useStyles } from './styles';

interface ISidebar {
  toggleSidebar: () => void;
}

const Sidebar = ({ toggleSidebar }: ISidebar) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const { role, customer, customerId } = useAppSelector((state) => state.myAccountSlice);

  const [open, setOpen] = React.useState<{
    [x: string]: boolean;
  }>({});

  const pathArr = location.pathname.split('/');

  const handleOpen = (name: string) => {
    let hasCollapsed;
    const hasOpened = _.has(open, name);
    if (hasOpened) {
      hasCollapsed = !open[name];
    } else {
      hasCollapsed = !hasOpened;
    }
    setOpen({
      [name]: hasCollapsed,
    });
  };

  const handleClickCustomerUser = () => {
    const from = `/customers/${customerId}/manage`;

    if (!(role?.permissions || []).includes(EPermissions.UPDATE_CUSTOMER)) {
      return navigate(`${from}/stations`, { replace: true });
    }

    if (!customerId || !customer) return;

    return navigate(from);
  };

  const renderIcon = (menu: IMenuRoute) => {
    let Icon;
    if (pathArr.includes(menu.key)) {
      Icon = menu.activeIcon;
    } else {
      Icon = menu.icon;
    }
    return <Icon />;
  };

  const renderMenuList = () => {
    return MENU_LIST.map((menu, index) => {
      if (!menu.isHaveMenu) return;

      const permissions = role.permissions || [];
      const isShow = _.isEmpty(_.intersection(menu.permission, permissions));

      if ((role.level !== ROLE_LEVEL.SUPER_ADMIN && isShow && menu.permission) || !menu.isHaveMenu)
        return;

      const from = `/customers/${customerId}/${menu.key}`;

      return (
        <React.Fragment key={index}>
          <ListItemButton
            className={classNames({
              active:
                (menu.path?.includes(PATH.customerManagement) &&
                  location.pathname.includes(PATH.customerManagement)) ||
                pathArr.includes(menu.key) ||
                !_.isEmpty(menu?.subMenu?.find((item) => item.path === location.pathname)),
            })}
            onClick={() => {
              handleOpen(menu.id);
              menu?.path && navigate(from, { state: menu.key });
            }}
            classes={{ root: classes.item }}
          >
            <ListItemIcon>{renderIcon(menu)}</ListItemIcon>
            <ListItemText primary={t(menu.id)} />
            {menu?.subMenu && (
              <React.Fragment>
                {open?.[menu.id] ||
                !_.isEmpty(menu?.subMenu?.find((item) => item.path === location.pathname)) ? (
                  <ExpandLessOutlined />
                ) : (
                  <ExpandMore />
                )}
              </React.Fragment>
            )}
          </ListItemButton>
          {menu?.subMenu && (
            <Collapse
              in={
                open?.[menu.id] ||
                !_.isEmpty(menu?.subMenu?.find((item) => item.path === location.pathname))
              }
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {menu.subMenu.map((item, index) => (
                  <ListItemButton
                    className={classNames({
                      active_submenu: location.pathname === item.path,
                    })}
                    key={index}
                    sx={{ pl: 5, py: '6px' }}
                    classes={{ root: classes.item }}
                    onClick={() => {
                      navigate(item.path);
                    }}
                  >
                    <ListItemText primary={t(item.id)} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <Box className={classes.root}>
      <Box>
        <ListItemButton classes={{ root: classes.menuTop }} onClick={toggleSidebar}>
          <ListItemIcon
            classes={{
              root: classes.menuIcon,
            }}
          >
            <Menu />
          </ListItemIcon>
          <ListItemText primary={t('button.collapse.sidebar')} />
        </ListItemButton>
      </Box>
      <Divider />
      <Box className={classes.top}>
        <Scrollbars>
          <ListSidebar sx={{ p: 1 }}>
            {renderMenuList()}
            {(role?.permissions || []).includes(EPermissions.UPDATE_CUSTOMER) && (
              <ListItemButton
                className={classNames({
                  active: pathArr.includes('manage'),
                })}
                onClick={() => {
                  handleOpen('routes.customerManagement');
                  handleClickCustomerUser();
                }}
                classes={{ root: classes.item }}
              >
                <ListItemIcon>
                  {pathArr.includes('manage') ? (
                    <ManageAccountsIcon />
                  ) : (
                    <ManageAccountsOutlinedIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary={t('routes.customerManagement')} />
              </ListItemButton>
            )}
          </ListSidebar>
        </Scrollbars>
      </Box>

      {/* <Box sx={{ p: 1 }}>
        <ListSidebar>
          <ListItemButton
            onClick={() => {
              navigate(PATH.systemManagement);
            }}
            className={classNames({
              active: location.pathname === PATH.systemManagement,
            })}
          >
            <ListItemIcon>
              <SettingsOutlined />
            </ListItemIcon>

            <ListItemText primary={t('routes.systemManagement')} />
          </ListItemButton>
        </ListSidebar>
      </Box> */}
    </Box>
  );
};

export default Sidebar;
