import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'src/clients/http';
import { MODAL_IDS } from 'src/constants/modal';
import { IUser } from 'src/types/auth';
import { ICamera, ICameraFilter } from 'src/types/camera';
import { IPagination } from 'src/types/common';
import { ICustomerMembers, IOrderUser, IUserMember } from 'src/types/customer';
import { IRole } from 'src/types/role';
import { IStation, IStationFilter } from 'src/types/station';
import {
  IOrderMemberCamerasAdd,
  IUpdateOrderMemberStationsRequest,
  IUserFilter,
} from 'src/types/user';
import { toastMessage } from 'src/utils/toast';
import i18n from 'src/i18n';
import { closeModal } from '../common/modal/modal_slice';

export const addOrderUsers = createAsyncThunk<IUserMember, { roleId: string; memberIds: string[] }>(
  'orderUsers/addOrderUsers',
  async ({ roleId, memberIds }, { rejectWithValue }) => {
    try {
      const data = await client.addOrderUsers(roleId, memberIds);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getOrderUsers = createAsyncThunk<IUserMember[], void>(
  'orderUsers/getOrderUsers',
  async (_payload, { rejectWithValue }) => {
    try {
      const data = await client.getOrderUsers();
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getOrderUser = createAsyncThunk<IOrderUser, string>(
  'orderUsers/getOrderUser',
  async (memberId, { rejectWithValue }) => {
    try {
      const data = await client.getOrderUser(memberId);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getUnassignedOrderUsers = createAsyncThunk<ICustomerMembers[], string>(
  'orderUsers/getUnassignedOrderUsers',
  async (customerId, { rejectWithValue }) => {
    try {
      const data = await client.getUnassignedOrderUsers(customerId);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const deleteOrderUsers = createAsyncThunk<string, { memberId: string }>(
  'orderUsers/deleteOrderUsers',
  async ({ memberId }, { rejectWithValue, dispatch }) => {
    try {
      await client.deleteOrderUsers(memberId);
      dispatch(closeModal({ modalId: MODAL_IDS.confirmDelete }));
      toastMessage.success(i18n.t('message.deleteUser.success'));
      return memberId;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getOrderMemberCameras = createAsyncThunk<
  { cameras: ICamera[]; trackAllCameras: boolean },
  { memberId: string; params: ICameraFilter }
>('orderUsers/getOrderMemberCameras', async (payload, { rejectWithValue }) => {
  try {
    const data = await client.getOrderMemberCameras(payload.memberId, payload.params);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateOrderMemberCameras = createAsyncThunk<void, IOrderMemberCamerasAdd>(
  'orderUsers/updateOrderMemberCameras',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.updateOrderMemberCameras(payload);

      toastMessage.success(i18n.t('message.update.success'));
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getOrderMemberStations = createAsyncThunk<
  { stations: IStation[]; trackAllStations: boolean },
  { memberId: string; params: IStationFilter }
>('orderUsers/getOrderMemberStations', async ({ memberId, params }, { rejectWithValue }) => {
  try {
    const data = await client.getOrderMemberStations(memberId, params);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateOrderMemberStations = createAsyncThunk<void, IUpdateOrderMemberStationsRequest>(
  'orderUsers/updateOrderMemberStations',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.updateOrderMemberStations(payload);

      toastMessage.success(i18n.t('message.update.success'));
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const getOrderUserRoles = createAsyncThunk<IRole[], void>(
  'orderUsers/getOrderUserRoles',
  async (_payload, { rejectWithValue }) => {
    try {
      const roles = await client.getOrderUserRoles();
      return roles;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const transferMembership = createAsyncThunk<
  void,
  { memberId: string; alternateOwnerId: string }
>('orderUsers/transferMembership', async (payload, { rejectWithValue }) => {
  try {
    await client.transferMembership(payload.memberId, payload.alternateOwnerId);
    return;
  } catch (error: any) {
    toastMessage.error(error?.message);
    return rejectWithValue(error);
  }
});

export const getUsers = createAsyncThunk<IPagination<IUser>, IUserFilter>(
  'user/getUsers',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await client.getUsers(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
