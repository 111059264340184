import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useEffect, useState } from 'react';
import { toast, useToasterStore } from 'react-hot-toast';
import { I18nextProvider } from 'react-i18next';

import Authentication from './components/authentication';
import ModalController from './components/modal';
import GlobalDndContext from './dnd-context';
import i18n from './i18n';
import Routes from './routes';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/min/locales';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './App.css';
import EncourageUseApplication from './components/modal/drawer_download_app';
import config from './config';

const TOAST_LIMIT = 1;

const App = () => {
  const { toasts } = useToasterStore();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const handleOpenDrawer = (status: boolean) => {
    setOpenDrawer(status);
  };

  const detectMobile = () => {
    const toMatch = [
      /Android/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      // /webOS/i,
      // /BlackBerry/i,
      // /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };

  useEffect(() => {
    const locale = localStorage.getItem('i18nextLng') || 'en';
    Cookies.set('locale', locale, { path: '/', domain: config.domain });
    i18n.changeLanguage(locale);
    moment.locale(locale);
  }, []);

  useEffect(() => {
    if (detectMobile() && !sessionStorage.getItem('download-app-dismiss')) {
      handleOpenDrawer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation: ;
  }, [toasts]);

  return (
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <GlobalDndContext>
          <Authentication />
          <Routes />
          <ModalController />
          <EncourageUseApplication open={openDrawer} handleOpenDrawer={handleOpenDrawer} />
        </GlobalDndContext>
      </LocalizationProvider>
    </I18nextProvider>
  );
};

export default App;
