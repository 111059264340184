import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ConfigMapTypes } from 'src/constants/map';
import {
  ECameraAction,
  ECameraStatus,
  ICamera,
  ICameraFeature,
  ICameraFunction,
  ICameraStream,
} from 'src/types/camera';
import { ILocation } from 'src/types/common';
import {
  createCamera,
  createCameraStation,
  deleteCamera,
  deleteCameraStation,
  getCameraFunction,
  getCameraStationFunction,
  getCameras,
  getCamerasOfConfigs,
  refreshSnapshot,
  updateCameraFunction,
  updateCameraGeneral,
  updateCameraStationFunction,
  updateCameraStationGeneral,
} from './camera_action';
import {
  deleteCameraOfCamerasUtil,
  deleteCameraUtil,
  updateStatusOfCameraUtil,
  updateStatusOfCamerasUtil,
} from 'src/utils/camera';

interface ICameraState {
  cameras: ICamera[];
  selectedCamera: ICamera | null;
  cameraAction: ECameraAction;
  isEditingLocation: boolean;
  createLocation: ILocation | null;
  updateLocation: ILocation | null;
  player: ICamera | null;
  isDrawZone: {
    status: boolean;
    snapshot: string;
  };
  functions: ICameraFunction | null;
  aiFeatures: ICameraFeature[];
  refreshSnapshotRandom: number;
  aiDimension: {
    width: number;
    height: number;
  } | null;
}

const initialState: ICameraState = {
  cameras: [],
  selectedCamera: null,
  cameraAction: ECameraAction.CLOSE,
  isEditingLocation: false,
  createLocation: ConfigMapTypes.location,
  updateLocation: null,
  player: null,
  isDrawZone: {
    status: false,
    snapshot: '',
  },
  functions: null,
  aiFeatures: [],
  refreshSnapshotRandom: new Date().getTime(),
  aiDimension: null,
};

const cameraSlice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    changeCameraStatus: (
      state: ICameraState,
      action: PayloadAction<{
        cameraId: string;
        status: ECameraStatus;
      }>,
    ) => {
      const { cameraId, status } = action.payload;

      const cameraSelected = state.selectedCamera ? { ...state.selectedCamera } : null;
      const newCameraSelected = updateStatusOfCameraUtil(cameraSelected, cameraId, status);
      state.selectedCamera = newCameraSelected;

      const cameras = [...state.cameras];

      if (cameras.length) {
        const newCameras = updateStatusOfCamerasUtil(cameras, cameraId, status);
        state.cameras = newCameras;
      }
    },

    deleteCameraLocal: (state: ICameraState, action: PayloadAction<string>) => {
      const cameraId = action.payload;
      const camera = state.selectedCamera ? { ...state.selectedCamera } : null;
      const newCameras = deleteCameraOfCamerasUtil([...state.cameras], cameraId);
      const newCamera = deleteCameraUtil(camera, cameraId);
      state.cameras = newCameras;
      state.selectedCamera = newCamera;
    },

    setCameraAction: (state: ICameraState, action: PayloadAction<ECameraAction>) => {
      state.cameraAction = action.payload;
    },

    setIsEditingCameraLocation: (state: ICameraState, action: PayloadAction<boolean>) => {
      state.isEditingLocation = action.payload;
    },

    setCreateCameraLocation: (state: ICameraState, action: PayloadAction<ILocation>) => {
      state.createLocation = action.payload;
    },

    setUpdateCameraLocation: (state: ICameraState, action: PayloadAction<ILocation | null>) => {
      state.updateLocation = action.payload;
    },

    setSelectedCamera: (state: ICameraState, action: PayloadAction<ICamera | null>) => {
      state.selectedCamera = action.payload;
    },

    setCameraPlayer: (state: ICameraState, action: PayloadAction<ICamera | null>) => {
      state.player = action.payload;
    },

    setIsDrawZone: (
      state: ICameraState,
      action: PayloadAction<{
        status: boolean;
        snapshot: string;
      }>,
    ) => {
      state.isDrawZone = action.payload;
    },

    setFeaturesDimension: (state: ICameraState, action: PayloadAction<ICameraStream | null>) => {
      state.aiFeatures = action.payload?.aiFeatures || [];
      state.aiDimension = action.payload?.dimension || null;
    },

    resetCameraFunction: (state: ICameraState) => {
      state.functions = initialState.functions;
      state.aiDimension = initialState.aiDimension;
      state.aiFeatures = initialState.aiFeatures;
      state.isDrawZone = initialState.isDrawZone;
    },

    resetCameraState: (state: ICameraState) => {
      state.cameras = initialState.cameras;
      state.selectedCamera = initialState.selectedCamera;
      state.cameraAction = initialState.cameraAction;
      state.isEditingLocation = initialState.isEditingLocation;
      state.createLocation = initialState.createLocation;
      state.updateLocation = initialState.updateLocation;
      state.player = initialState.player;
      state.isDrawZone = initialState.isDrawZone;
      state.functions = initialState.functions;
      state.aiFeatures = initialState.aiFeatures;
      state.aiDimension = initialState.aiDimension;
    },

    removeCamerasByListCameraId: (state: ICameraState, action: PayloadAction<string[]>) => {
      const cameraIds = action.payload;
      const currentData = [...state.cameras];

      const newData = currentData.filter((item) => !cameraIds.includes(item.id));
      state.cameras = newData;
    },
  },

  extraReducers(builder) {
    builder.addCase(createCamera.fulfilled, (state, action) => {
      state.cameras = [...state.cameras, action.payload];
    });

    builder.addCase(createCameraStation.fulfilled, (state, action) => {
      state.cameras = [...state.cameras, action.payload];
    });

    builder.addCase(updateCameraGeneral.fulfilled, (state, action) => {
      const cameras = [...state.cameras];

      const index = cameras.findIndex((cam) => cam.id === action.payload.cameraId);

      if (index !== -1) {
        cameras.splice(index, 1, {
          ...cameras[index],
          address: action.payload.data.address,
          name: action.payload.data.name,
          lat: action.payload.data.lat,
          lng: action.payload.data.lng,
        });
      }

      state.cameras = cameras;
    });

    builder.addCase(updateCameraStationGeneral.fulfilled, (state, action) => {
      const index = state.cameras.findIndex((cam) => cam.id === action.payload.cameraId);

      if (index !== -1) {
        const cameras = [...state.cameras];
        cameras.splice(index, 1, {
          ...cameras[index],
          address: action.payload.data.address,
          name: action.payload.data.name,
          lat: action.payload.data.lat,
          lng: action.payload.data.lng,
        });
        state.cameras = cameras;
      }
    });

    builder.addCase(deleteCamera.fulfilled, (state, action) => {
      const cameras = state.cameras.filter((cam) => cam.id !== action.payload);

      state.cameras = cameras;
    });

    builder.addCase(deleteCameraStation.fulfilled, (state, action) => {
      const cameras = state.cameras.filter((cam) => cam.id !== action.payload.cameraId);

      state.cameras = cameras;
    });

    builder.addCase(getCameras.fulfilled, (state, action) => {
      state.cameras = action.payload;
    });

    builder.addCase(getCamerasOfConfigs.fulfilled, (state, action) => {
      state.cameras = action.payload;
    });

    builder.addCase(getCameraFunction.pending, (state) => {
      state.isDrawZone = {
        status: false,
        snapshot: '',
      };
    });

    builder.addCase(getCameraFunction.fulfilled, (state, action) => {
      state.functions = action.payload;
      state.aiFeatures = action.payload?.aiEnabled?.aiFeatures || [];
      state.aiDimension = action.payload?.aiEnabled?.dimension || null;
    });

    builder.addCase(getCameraStationFunction.pending, (state) => {
      state.isDrawZone = {
        status: false,
        snapshot: '',
      };
    });

    builder.addCase(getCameraStationFunction.fulfilled, (state, action) => {
      state.functions = action.payload;
      state.aiFeatures = action.payload?.aiEnabled?.aiFeatures || [];
      state.aiDimension = action.payload?.aiEnabled?.dimension || null;
    });

    builder.addCase(updateCameraFunction.fulfilled, (state, action) => {
      const index = state.cameras.findIndex((camera) => camera.id === action.payload.cameraId);

      if (index !== -1) {
        const cameras = [...state.cameras];
        cameras.splice(index, 1, {
          ...cameras[index],
          status: action.payload.functionEnabled ? ECameraStatus.NORMAL : ECameraStatus.OFFLINE,
        });
        state.cameras = cameras;
      }
    });

    builder.addCase(updateCameraStationFunction.fulfilled, (state, action) => {
      const index = state.cameras.findIndex((camera) => camera.id === action.payload.cameraId);

      if (index !== -1) {
        const cameras = [...state.cameras];
        cameras.splice(index, 1, {
          ...cameras[index],
          status: action.payload.functionEnabled ? ECameraStatus.NORMAL : ECameraStatus.OFFLINE,
        });
        state.cameras = cameras;
      }
    });

    builder.addCase(refreshSnapshot.fulfilled, (state) => {
      state.refreshSnapshotRandom = new Date().getTime();
    });
  },
});

export const {
  changeCameraStatus,
  setCameraAction,
  setCreateCameraLocation,
  setIsEditingCameraLocation,
  setSelectedCamera,
  setUpdateCameraLocation,
  setCameraPlayer,
  setFeaturesDimension,
  setIsDrawZone,
  resetCameraFunction,
  resetCameraState,
  removeCamerasByListCameraId,
  deleteCameraLocal,
} = cameraSlice.actions;

export default cameraSlice.reducer;
