import { IUser } from 'src/types/auth';
import { ICamera, ICameraFilter } from 'src/types/camera';
import { IPagination } from 'src/types/common';
import { ICustomerMembers, IOrderUser, IUserMember } from 'src/types/customer';
import { IRole } from 'src/types/role';
import { IStation, IStationFilter } from 'src/types/station';
import {
  IOrderMemberCamerasAdd,
  IUpdateOrderMemberStationsRequest,
  IUserFilter,
} from 'src/types/user';
import ClientBase from './base';

export interface ClientUserMix {
  getUsers: (data: IUserFilter) => Promise<IPagination<IUser>>;

  addOrderUsers: (roleId: string, memberIds: string[]) => Promise<IUserMember>;
  getOrderUsers: () => Promise<IUserMember[]>;
  getOrderUser: (memberId: string) => Promise<IOrderUser>;
  getUnassignedOrderUsers: (customerId: string) => Promise<ICustomerMembers[]>;
  getOrderMemberCameras: (
    memberId: string,
    params: ICameraFilter,
  ) => Promise<{ cameras: ICamera[]; trackAllCameras: boolean }>;
  getOrderMemberStations: (
    memberId: string,
    params: IStationFilter,
  ) => Promise<{ stations: IStation[]; trackAllStations: boolean }>;

  getOrderUserRoles: () => Promise<IRole[]>;

  updateOrderMemberCameras: (payload: IOrderMemberCamerasAdd) => Promise<void>;
  updateOrderMemberStations: (payload: IUpdateOrderMemberStationsRequest) => Promise<void>;

  deleteOrderUsers: (memberId: string) => Promise<void>;

  transferMembership: (memberId: string, alternateOwnerId: string) => Promise<void>;
}

const ClientUser = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientUserMix {
    getUsers = async (data: IUserFilter) => {
      return this.doFetch<IPagination<IUser>>(`${this.getBaseRoute()}/customers/users/search`, {
        method: 'post',
        data: data,
      });
    };

    addOrderUsers = async (roleId: string, memberIds: string[]) => {
      return this.doFetch<IUserMember>(`${this.getBaseOrderRoute()}/members`, {
        method: 'post',
        data: { memberIds, roleId },
      });
    };

    getOrderUsers = async () => {
      return this.doFetch<IUserMember[]>(`${this.getBaseOrderRoute()}/members`, {
        method: 'GET',
      });
    };

    getOrderUser = async (memberId: string) => {
      return this.doFetch<IOrderUser>(`${this.getBaseOrderRoute()}/members/${memberId}/info`, {
        method: 'get',
      });
    };

    getUnassignedOrderUsers = async (customerId: string) => {
      return this.doFetch<ICustomerMembers[]>(
        `${this.getBaseRoute()}/customers/${customerId}/members`,
        {
          method: 'POST',
          data: {
            excludingOrderId: this.orderId,
          },
        },
      );
    };

    deleteOrderUsers = async (memberId: string) => {
      return this.doFetch<void>(`${this.getBaseOrderRoute()}/members/${memberId}`, {
        method: 'delete',
      });
    };

    //member cameras
    updateOrderMemberCameras = async (payload: IOrderMemberCamerasAdd) => {
      const { cameraIds, memberId, trackAllCameras } = payload;
      return this.doFetch<void>(`${this.getBaseOrderRoute()}/members/${memberId}/cameras`, {
        method: 'put',
        data: { cameraIds, trackAllCameras },
      });
    };

    getOrderMemberCameras = async (memberId: string, params: ICameraFilter) => {
      return this.doFetch<{ cameras: ICamera[]; trackAllCameras: boolean }>(
        `${this.getBaseOrderRoute()}/members/${memberId}/cameras`,
        {
          method: 'get',
          params,
        },
      );
    };

    //member stations
    updateOrderMemberStations = async (payload: IUpdateOrderMemberStationsRequest) => {
      const { stationIds, memberId, trackAllStations } = payload;
      return this.doFetch<void>(`${this.getBaseOrderRoute()}/members/${memberId}/stations`, {
        method: 'put',
        data: { stationIds, trackAllStations },
      });
    };

    getOrderMemberStations = async (memberId: string, params: IStationFilter) => {
      return this.doFetch<{ stations: IStation[]; trackAllStations: boolean }>(
        `${this.getBaseOrderRoute()}/members/${memberId}/stations`,
        {
          method: 'get',
          params,
        },
      );
    };

    transferMembership = (memberId: string, alternateOwnerId: string) => {
      return this.doFetch<void>(
        `${this.getBaseOrderRoute()}/members/${memberId}/transfer_membership`,
        {
          method: 'put',
          data: { alternateOwnerId },
        },
      );
    };

    // role
    getOrderUserRoles = async () => {
      return this.doFetch<IRole[]>(`${this.getBaseOrderRoute()}/roles/all`, {
        method: 'get',
      });
    };

    // getUnassignedMemberStations = async (
    //   orderId: string,
    //   memberId: string,
    //   stationIds: string[],
    // ) => {
    //   return this.doFetch<void>(`${memberRouter}/${orderId}/members/${memberId}/stations`, {
    //     method: 'put',
    //     data: { stationIds },
    //   });
    // };
  };

export default ClientUser;
