import { TFunction } from 'react-i18next';
import { ICustomer, IOrderMemberStatus, TEntityType } from 'src/types/customer';

export const EntityTypeOptions = (t: any): { id: TEntityType; name: string }[] => [
  { id: 'individual', name: t('label.personal') },
  { id: 'enterprise', name: t('label.organization.main') },
];

export const initCustomerState: ICustomer = {
  name: '',
  address: '',
  email: '',
  phone: '',
  description: '',
  entityType: 'individual',
  // ownerId: '',
};

export const OrderMemberStatusOptions = (
  t: TFunction<'translation', undefined>,
): IOrderMemberStatus[] => [
  {
    id: 'pending',
    name: t('label.status.pending'),
    color: 'default',
  },
  {
    id: 'accepted',
    name: t('label.status.accepted'),
    color: 'primary',
  },
  {
    id: 'rejected',
    name: t('label.status.rejected'),
    color: 'error',
  },
  {
    id: 'deleted',
    name: t('label.status.deleted'),
    color: 'default',
  },
];
