import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import notFount from '../../assets/images/not_found.png';

import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { useAppSelector } from 'src/redux_store';
import { getStationMapRoute } from 'src/utils/helper';

const NotFound = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { customerId } = useAppSelector(({ myAccountSlice }) => myAccountSlice);

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <img
          src={notFount}
          alt="notFount"
          style={{
            width: '400px',
          }}
        />
        <Typography variant="h6" sx={{ fontSize: '30px', fontWeight: '500', fontFamily: 'Roboto' }}>
          {t('label.pageNotFound.main')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Typography sx={{ padding: '10px 0' }}>{t('label.pageNotFound.description')}</Typography>

          <Box>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(getStationMapRoute(customerId));
              }}
            >
              <KeyboardReturnOutlinedIcon sx={{ marginRight: '5px' }} /> {t('label.goHomePage')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
