import { IPagination } from 'src/types/common';
import ClientBase from './base';
import { IRole, IRoleData, IRoleFilter } from 'src/types/role';
import { IUser } from 'src/types/auth';

export interface ClientRoleMix {
  createRole: (data: IRole) => Promise<IRole>;
  getAllRoles: () => Promise<IRole[]>;
  searchRoles: (data: IRoleFilter) => Promise<IPagination<IRoleData>>;
  getUsersInRole: (roleId: string) => Promise<IUser[]>;
  getRole: (roleId: string) => Promise<IRole>;
  updateRole: (roleId: string, data: IRole) => Promise<IRole>;
  deleteRole: (roleId: string) => Promise<string>;
}

const ClientRole = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientRoleMix {
    createRole = async (data: IRole) => {
      return this.doFetch<IRole>(`${this.getRoleRoute()}`, {
        method: 'post',
        data,
      });
    };
    searchRoles = async (data: IRoleFilter) => {
      return this.doFetch<IPagination<IRoleData>>(`${this.getRoleRoute()}/search`, {
        method: 'post',
        data,
      });
    };
    getAllRoles = async () => {
      return this.doFetch<IRole[]>(`${this.getRoleRoute()}`, {
        method: 'get',
      });
    };
    getUsersInRole = async (roleId: string) => {
      return this.doFetch<IUser[]>(`${this.getRoleRoute()}/${roleId}/users`, {
        method: 'get',
      });
    };
    getRole = async (roleId: string) => {
      return this.doFetch<IRole>(`${this.getRoleRoute()}/${roleId}`, {
        method: 'get',
      });
    };
    updateRole = async (roleId: string, data: IRole) => {
      return this.doFetch<IRole>(`${this.getRoleRoute()}/${roleId}`, {
        method: 'put',
        data,
      });
    };
    deleteRole = async (roleId: string) => {
      return this.doFetch<string>(`${this.getRoleRoute()}/${roleId}`, {
        method: 'delete',
      });
    };
  };

export default ClientRole;
