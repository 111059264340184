import { IPagination } from 'src/types/common';
import { IRole, IRoleData, IRoleFilter, initRoleFilter } from 'src/types/role';
import { createRole, deleteRole, getAllRoles, getRole, searchRoles } from './role_action';
import { createSlice } from '@reduxjs/toolkit';
import { IPermission } from 'src/types/permission';

interface IRoleSliceState {
  role: IPagination<IRoleData | IRole>;
  filter: IRoleFilter;
  idRoleSelected: string;
  permissionList: IPermission[];
  roleDetail: IRole | null;
}

const initialState: IRoleSliceState = {
  role: {
    data: [],
    page: 0,
    total: 0,
    totalPage: 0,
  },
  idRoleSelected: '',
  filter: initRoleFilter,
  permissionList: [],
  roleDetail: null,
};

const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoleDetail: (state, action) => {
      const roleDetail = action.payload;
      state.roleDetail = roleDetail;
    },
    resetRoleDetail: (state) => {
      state.roleDetail = null;
    },
    changeParamsFilterRoles: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    setIdRoleSelected: (state, action: { payload: string }) => {
      state.idRoleSelected = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(createRole.fulfilled, (state, action) => {
      state.role.data.push(action.payload);
    });
    builder.addCase(searchRoles.fulfilled, (state, action) => {
      state.role = action.payload;
      state.roleDetail = null;
    });
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      const data = action.payload;
      state.role.data = data;
    });
    builder.addCase(getRole.fulfilled, (state, action) => {
      state.roleDetail = action.payload;
    });
    // builder.addCase(updateRole.fulfilled, (state, action) => {
    //   const { permissionsName } = getPermissionsName(
    //     state.permissionList,
    //     action.payload.permissions,
    //     '',
    //   );

    //   state.role.data = state.role.data.map((role) =>
    //     role.id === action.payload.id
    //       ? {
    //           ...role,
    //           ...action.payload,
    //           permissionsName,
    //         }
    //       : role,
    //   );
    //   state.roleDetail = { ...state.roleDetail, ...action.payload };
    // });
    builder.addCase(deleteRole.fulfilled, (state, action) => {
      state.role.data = state.role.data.filter((role) => role.id !== action.payload);
      state.roleDetail = null;
    });
  },
});

export const { changeParamsFilterRoles, setRoleDetail, setIdRoleSelected, resetRoleDetail } =
  roleSlice.actions;

export default roleSlice.reducer;
