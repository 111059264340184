import { createAsyncThunk } from '@reduxjs/toolkit';

import client from 'src/clients/http';
import { MODAL_IDS } from 'src/constants/modal';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';
import { ICamera } from 'src/types/camera';
import { toastMessage } from 'src/utils/toast';

export const addOrderCameras = createAsyncThunk<
  ICamera[],
  { customerId: string; orderId: string; cameras: string[] }
>('orderCameras/addOrderCameras', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const customers = await client.addOrderCameras(
      payload.customerId,
      payload.orderId,
      payload.cameras,
    );

    toastMessage.success('Thêm mới thành công');
    dispatch(closeModal({ modalId: MODAL_IDS.customer.orders.addCamera }));

    return customers;
  } catch (error: any) {
    toastMessage.error(error?.message);
    return rejectWithValue(error);
  }
});

export const getOrderCameras = createAsyncThunk<ICamera[], { customerId: string; orderId: string }>(
  'orderCameras/getOrderCameras',
  async (payload, { rejectWithValue }) => {
    try {
      const customers = await client.getOrderCameras(payload.customerId, payload.orderId);
      return customers;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getCameraWithoutCustomer = createAsyncThunk<ICamera[], string>(
  'orderCameras/getCameraWithoutCustomer',
  async (searchKeyword, { rejectWithValue }) => {
    try {
      const customers = await client.getCameraWithoutCustomer(searchKeyword);
      return customers;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const deleteOrderCamera = createAsyncThunk<
  string,
  { customerId: string; orderId: string; cameraId: string }
>('orderCameras/deleteOrderCamera', async (payload, { rejectWithValue }) => {
  try {
    await client.deleteOrderCamera(payload.customerId, payload.orderId, payload.cameraId);
    return payload.cameraId;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
