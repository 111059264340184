import { createSlice } from '@reduxjs/toolkit';

import { ICamera } from 'src/types/camera';
import { ICustomerMembers, IOrderUser, IUserMember } from 'src/types/customer';
import { IRole } from 'src/types/role';
import { IStation } from 'src/types/station';
import { ICustomerMember } from 'src/types/user';
import { getCamerasOfConfigs } from '../camera/camera_action';
import { getStations } from '../station/station_action';
import {
  deleteOrderUsers,
  getOrderMemberCameras,
  getOrderMemberStations,
  getOrderUserRoles,
  getOrderUsers,
  getOrderUser,
  getUnassignedOrderUsers,
  getUsers,
  addOrderUsers,
} from './user_actions';
import { IPagination } from 'src/types/common';
import { IUser } from 'src/types/auth';

interface IPayloadTransfer {
  payload: {
    fieldFromTransfer:
      | 'assignedCameras'
      | 'cameras'
      | 'assignedStations'
      | 'stations'
      | 'assignedMembers'
      | 'members';
    fieldToTransfer:
      | 'assignedCameras'
      | 'cameras'
      | 'assignedStations'
      | 'stations'
      | 'assignedMembers'
      | 'members';
    dataTransfer: ICamera[] | IStation[] | ICustomerMember[];
  };
}

interface IDataState {
  assignedCameras: ICamera[];
  cameras: ICamera[];

  assignedStations: IStation[];
  stations: IStation[];

  customerMembers: ICustomerMembers[];
  members: IUserMember[];
}

interface IOrderMemberSliceState {
  data: IDataState;
  memberDetail: IOrderUser | null;
  users: IPagination<IUser>;
  roles: IRole[];
  trackAllCameras: boolean;
  trackAllStations: boolean;
}

const initialState: IOrderMemberSliceState = {
  data: {
    assignedCameras: [],
    assignedStations: [],
    customerMembers: [],

    cameras: [],
    stations: [],
    members: [],
  },
  users: {
    data: [],
    page: 0,
    total: 0,
    totalPage: 0,
  },
  roles: [],
  memberDetail: null,
  trackAllCameras: false,
  trackAllStations: false,
};

const orderMemberSlice = createSlice({
  name: 'orderMemberSlice',
  initialState,
  reducers: {
    transferData: (state, action: IPayloadTransfer) => {
      const { fieldFromTransfer, fieldToTransfer, dataTransfer } = action.payload;
      const cloneDataList = [...state.data[fieldFromTransfer as keyof IDataState]];

      dataTransfer.map((item: any) => {
        const index = cloneDataList.findIndex((data: any) => data.id === item.id);
        cloneDataList.splice(index, 1);
      });

      const cloneDataListSelected = [...state.data[fieldToTransfer as keyof IDataState]];
      const newDataSelectedList = cloneDataListSelected.concat(dataTransfer as any);

      state.data[fieldFromTransfer as keyof IDataState] = cloneDataList as any[];
      state.data[fieldToTransfer as keyof IDataState] = newDataSelectedList as any[];
    },
    changeTrackAll: (
      state,
      action: { payload: { type: 'trackAllCameras' | 'trackAllStations'; checked: boolean } },
    ) => {
      state[action.payload.type] = action.payload.checked;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        const data = action.payload;

        state.users = data;
      })
      .addCase(getOrderUsers.fulfilled, (state, action) => {
        state.data.members = action.payload;
      })
      .addCase(addOrderUsers.fulfilled, (state, action) => {
        state.data.members = [...state.data.members, action.payload];
        state.data.customerMembers = state.data.customerMembers.filter(
          (item) => item.user.id !== action.payload.user.id,
        );
      })
      .addCase(getUnassignedOrderUsers.fulfilled, (state, action) => {
        state.data.customerMembers = action.payload;
      })
      .addCase(getCamerasOfConfigs.fulfilled, (state, action) => {
        const newCameras = action.payload.filter(
          (camera) =>
            !state.data.assignedCameras.some((cameraAssigned) => cameraAssigned.id === camera.id),
        );

        state.data.cameras = newCameras;
      })
      .addCase(getStations.fulfilled, (state, action) => {
        const newStations = action.payload.filter(
          (station) =>
            !state.data.assignedStations.some(
              (stationAssigned) => stationAssigned.id === station.id,
            ),
        );
        state.data.stations = newStations;
      })
      .addCase(getOrderUserRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
      })
      .addCase(getOrderMemberCameras.fulfilled, (state, action) => {
        state.trackAllCameras = action.payload.trackAllCameras;
        state.data.assignedCameras = action.payload.cameras;
      })
      .addCase(getOrderMemberStations.fulfilled, (state, action) => {
        state.trackAllStations = action.payload.trackAllStations;
        state.data.assignedStations = action.payload.stations;
      })
      .addCase(getOrderUser.fulfilled, (state, action) => {
        state.memberDetail = action.payload;
      })
      .addCase(deleteOrderUsers.fulfilled, (state, action) => {
        state.data.members = state.data.members.filter(
          (member) => member.user.id !== action.payload,
        );
      });
  },
});

const { actions } = orderMemberSlice;
export const { transferData, changeTrackAll } = actions;
export default orderMemberSlice.reducer;
