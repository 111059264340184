import { ReactNode } from 'react';
import { TVehicleType } from './events';
import { ICommune, IDistrict, IProvince } from './political';
import { IStation, IStationFunction } from './station';

export interface IStatusColorCamera {
  normal: 'normal';
  error: 'error';
  warning: 'warning';
  offline: 'offline';
}

export enum ECameraStatus {
  NORMAL = 'normal',
  WARNING = 'warning',
  ERROR = 'error',
  OFFLINE = 'offline',
}

export enum ECameraAction {
  CREATE = 'create_camera',
  SHARE_LINK = 'create_camera_shareLink',
  STATION_CREATE = 'create_camera_station',
  STATION_SHARE_LINK = 'create_camera_station_shareLink',
  UPDATE = 'update_camera',
  CLOSE = 'close',
}

export interface IRtspLink {
  name: string;
  rtspLink: string;
}

export interface IZone {
  id?: string;
  type: number;
  name: string;
  title: string;
  color: string;
  shape: string;
  time_limit?: 'yes' | 'no';
  vehicle_limit?: 'yes' | 'no';
  arrow: {
    x: number;
    y: number;
  }[];
  vertices: {
    x: number;
    y: number;
  }[];
  direct: 'yes' | 'no';
  vehicle?: (TVehicleType | number)[];
  time?: {
    start:
      | {
          hour: number;
          minute: number;
        }
      | Date;
    end:
      | {
          hour: number;
          minute: number;
        }
      | Date;
  }[];
}

export interface ICameraFeature {
  key: string;
  title: string;
  zones: IZone[];
}

export interface ICameraStream {
  name: string;
  token: string;
  rtspLink: string;
  snapshot: string;
  recordEnabled: boolean;
  aiEnabled: boolean;
  streamEnabled: boolean;
  shareEnabled: boolean;
  width: number;
  height: number;
  fps: number;
  quality: number;
  encoder: string;
  channelId: number;
  resolution: {
    width: number;
    height: number;
  };
  resolutionRange: {
    width: number;
    height: number;
  }[];
  fpsRange: {
    Min: string;
    Max: string;
  };
  qualityRange: {
    Min: string;
    Max: string;
  };
  aiFeatures: ICameraFeature[];
  dimension: {
    width: number;
    height: number;
  } | null;
}

export interface ICamera {
  id: string;
  name: string;
  lat: number;
  lng: number;
  province: number;
  district: number;
  commune: number;
  address: string;
  description: string;
  ip: string;
  portOnvif: string;
  portRtsp: string;
  user: string;
  pass: string;
  isSharedLink: boolean;
  status: ECameraStatus;
  functionEnabled: boolean;
  isSupportPtz: boolean;
  isSupportAudio: boolean;
  isEnabledMic: boolean;
  isEnabledPtz: boolean;
  isEnabledSpeaker: boolean;
  isSyncDateTime: boolean;
  snapshot: string;

  streams: ICameraStream[];

  micVolume: number;
  speakerVolume: number;

  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export type TCameraGeneral = Pick<
  ICamera,
  | 'name'
  | 'lat'
  | 'lng'
  | 'province'
  | 'district'
  | 'commune'
  | 'address'
  | 'description'
  | 'ip'
  | 'portOnvif'
  | 'portRtsp'
  | 'user'
  | 'pass'
  | 'isSharedLink'
> & {
  stationId: string;
  rtspLinks: { name: string; rtspLink: string }[];
  addressFull?: string;
};

export type TCameraGeneralForm = Omit<
  TCameraGeneral,
  'stationId' | 'province' | 'district' | 'commune'
> & {
  isStationCamera: boolean;
  province: IProvince | null;
  district: IDistrict | null;
  commune: ICommune | null;
  stationId: IStation | null;
};

export interface IParamData {
  name: string;
  token: string;
  resolution: {
    width: number;
    height: number;
  };
  resolutionRange: {
    width: number;
    height: number;
  }[];
  quality: number;
  qualityRange: {
    Min: number;
    Max: number;
  };
  fps: number;
  fpsRange: {
    Min: number;
    Max: number;
  };
}

export interface ICameraConfig {
  streams: ICameraStream[];
  isEnabledMic: boolean;
  isEnabledPtz: boolean;
  isEnabledSpeaker: boolean;
  isSyncDateTime: boolean;
  stationFunctionEnabled?: boolean;
  stationId?: string;
}

export interface ICameraConfigPatch {
  param: IParamData | null;
  isEnabledMic: boolean;
  isEnabledPtz: boolean;
  isEnabledSpeaker: boolean;
  isSyncDateTime: boolean;
}

export interface ICameraFunction {
  stationFunction: IStationFunction | null;
  features: ICameraFeature[];
  streams: ICameraStream[];
  functionEnabled: boolean;
  aiEnabled: ICameraStream | null;
  streamEnabled: ICameraStream | null;
  recordEnabled: ICameraStream | null;
  shareEnabled: ICameraStream | null;
  functionStationEnabled?: boolean;
  stationId?: string;
}

export interface ICameraFilter {
  searchKeyword: string;
  province: string;
  district: string;
  commune: string;
  customer: string;
  station: string;
  status: string;
}

export interface ITransferCameraInfo {
  id: string;
  name: string;
}

export interface ICameraItemProps {
  camera: ICamera;
  isDrag?: boolean;
  icon?: ReactNode;
  idSelected?: string;
  selectCamera?: (camera: ICamera) => void;
  isShowLogsIcon?: boolean;
}
