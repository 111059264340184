import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux_store';
import EmptyCustomer from '../empty_customer';

const NotPermission = () => {
  const { customers, orders, role } = useAppSelector((state) => state.myAccountSlice);
  const { t } = useTranslation();

  const renderText = () => {
    if (!customers.length) return <EmptyCustomer />;

    if (!orders.length)
      return (
        <Box>
          <Typography>{t('label.notBelongOrder')}</Typography>
        </Box>
      );

    if (isEmpty(role)) return <Typography>{t('label.noPermission')}</Typography>;
  };

  return (
    <Box flex={1} display="flex" alignItems="center" justifyContent="center">
      {renderText()}
    </Box>
  );
};

export default NotPermission;
